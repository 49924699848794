<p-toast></p-toast>
<title title="Configuración > Permisos"></title>

<div class="global-main-table" *ngIf="roles">
  <div class="container-table">
    <div class="filters">
        <span class="p-input-icon-left">
          <div class="flex">
            <div >
              <p-dropdown
                [options]="roles"
                [(ngModel)]="selectedRol"
                optionLabel="name"
                [showClear]="false"
                (onChange)="onRolSelected($event)"
              ></p-dropdown>
            </div>
          </div>
        </span>
    
    
    <div *ngIf="isFilter">
    <span class="icon">
      <p-button icon="pi pi-filter-slash" styleClass="p-button-filter" (click)="clearFilter()" class="p-button-text"></p-button>
    </span>
    </div>
    
    </div>

    <p-table
    id="tableRoles"
    #dtRoles
    [value]="menuItems"
    [tableStyle]="{ 'min-width': '25rem' , 'margin-top':'1.5%', 'max-height': '20rem' }"
    [paginator]="true"
    [rows]="3"
    [rowsPerPageOptions]="[1, 2, 3, 4 , 5 , 6]"
    selectionMode="single"
    autocomplete="off"
    class="global-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Menu</th>
        <th>Submenu</th>
        <th>Ver</th>
        <th>Editar</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-module let-i="rowIndex">
      <!-- Mostrar el switch para el módulo -->
      <ng-container *ngIf="module.submenu && module.submenu.length > 0">
        <ng-container *ngFor="let submodule of module.submenu; let j = index">
          <tr>
            <ng-container *ngIf="j === 0">
              <td [attr.rowspan]="module.submenu.length">{{ module.title }}</td>
            </ng-container>
            <td>{{ submodule.title }}</td>
            <td>
              <p-inputSwitch [(ngModel)]="submodule.activo" (onChange)="updatePermissions(module, submodule)"></p-inputSwitch>
            </td>
            <td>
              <p-inputSwitch [(ngModel)]="submodule.editable" [disabled]="!submodule.activo" (onChange)="updateEditPermissions(module, submodule)"></p-inputSwitch>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!module.submenu || module.submenu.length === 0">
        <tr>
          <td>{{ module.title }}</td>
          <td></td>
          <td>
            <p-inputSwitch [(ngModel)]="module.activo" (onChange)="showModules(module)"></p-inputSwitch>
          </td>
          <td>
            <p-inputSwitch [(ngModel)]="module.editable" [disabled]="!module.activo"  (onChange)="showModules(module)"></p-inputSwitch>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>
  <div class="generatingBox">
    <button type="submit" class="btn btn-default float-right" style="background-color: #707dbf; color: white;" (click)="savePermissions()">Guardar</button>
    </div>

</div>