import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ApiService } from '../services/api-service/apiService/api.service';
import { TypeTransaction } from '../model/TypeTransaction';
import { Cotizacion } from '../model/Cotizacion';
import { AuthService } from '../auth/guards/auth-service.service';
import { CotizacionRequest } from '../model/CotizacionRequest';
import { NumberFormatService } from '../services/numberFormat/number-format-service.service';


@Component({
  selector: 'app-show-cotizacion',
  templateUrl: './show-cotizacion.component.html',
  styleUrls: ['./show-cotizacion.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowCotizacionComponent implements OnInit {
  typesOfTransaction!: TypeTransaction[];
  cotizaciones!: any[];
  valoresMonedas: { [moneda: string]: { compra?: number; venta?: number } } = {};
  displayBasic!: boolean;
  currency_id!:any;
  currencies!:any;
  cotizacion!: Cotizacion;
  cotizacionRequest!:CotizacionRequest;
  displayEdit!: boolean;
  selectedCurrency!: any;
  displayDelete!: boolean;
  ratio_id !: any;

  parsedNumber!: number;
  parsedNumber2!: number;
  userInputBuy: string = '';
  userInputSell: string = '';
  user!:any;
  canEdit: boolean = false;
  role: any;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,private authService: AuthService,private numberFormatService: NumberFormatService) {
      this.user = this.authService.getCurrentUser();
      this.role = this.authService.userRole;
      console.log(this.role);
      if (this.user && this.user.permissions && this.user.permissions['cotizaciones']) {
        this.canEdit = this.user.permissions['cotizaciones'].includes('mostrar_editar') || this.role == 'Administrador';
      }
    }


    ngOnInit() {
      this.getCotizaciones();
      this.cotizacion = new Cotizacion();
      this.cotizacionRequest = new CotizacionRequest();
      this.getPar();
      this.selectedCurrency = undefined;
    }

  getCotizaciones(){
    this.apiService.get(ApiService.apiURLBack + 'ratio').subscribe({
      next: (response: any) => {
        this.cotizaciones = response;
        console.log(this.cotizaciones);
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPar(){
    this.apiService.get(ApiService.apiURLBack + 'par').subscribe({
      next: (response: any) => {
        this.currencies = response;
            // Forzar la actualización de la tabla
      this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  generatingCotizacion(){
  this.displayBasic =true;
  this.cotizacion = new Cotizacion();
  this.cotizacionRequest = new CotizacionRequest();
  this.cotizacion.pair_id = this.currency_id;
  }


  

  saveNewCotization() {
    console.log(this.cotizacion);

    if (this.cotizacion.mount_buy === undefined || this.cotizacion.mount_buy === '' ||
    this.cotizacion.mount_sell === undefined || this.cotizacion.mount_sell === '' ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Por favor completar todos los campos',
        life: 3000,
      });
      return;
    }
  
    this.cotizacionRequest.mount_buy = parseFloat(this.cotizacion.mount_buy);
    this.cotizacionRequest.mount_sell = parseFloat(this.cotizacion.mount_sell);
    // Resto del código para asignar pair_id y realizar la solicitud al servicio API
    this.cotizacion.pair_id = this.selectedCurrency.id;
    this.cotizacionRequest.pair_id = this.selectedCurrency.id;
    this.displayBasic = false;

    if (
      this.cotizacionRequest.mount_buy !== undefined &&
      this.cotizacionRequest.pair_id !== undefined &&
      this.cotizacionRequest.mount_sell !== undefined
    ) {
      console.log(this.cotizacion);
      this.apiService
        .post(ApiService.apiURLBack + 'ratio', this.cotizacion)
        .subscribe({
          next: (response: any) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Cotización agregada exitosamente',
              life: 2000,
            });
            this.displayEdit = false;
            this.displayBasic = false;
            this.cotizacionRequest = new CotizacionRequest();
            this.cotizacion = new Cotizacion();
            this.getCotizaciones();
            this.cdr.detectChanges();
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error al generar una cotización',
              life: 2000,
            });
          },
        });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Debe completar todos los campos',
        life: 3000,
      });
      return;
    }
  }

  saveEditCotization(cotization:any){
   
    console.log(this.cotizacion);

    if (this.cotizacion.mount_buy === undefined || this.cotizacion.mount_buy === '' ||
    this.cotizacion.mount_sell === undefined || this.cotizacion.mount_sell === '' ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Por favor completar todos los campos',
        life: 3000,
      });
      return;
    }
    else {
    this.displayBasic = false;
    {
      console.log(this.cotizacion);
      this.apiService
        .post(ApiService.apiURLBack + 'ratio', this.cotizacion)
        .subscribe({
          next: (response: any) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Cotización agregada exitosamente',
              life: 2000,
            });
            this.displayEdit = false;
            this.displayBasic = false;
            this.cotizacionRequest = new CotizacionRequest();
            this.cotizacion = new Cotizacion();
            this.getCotizaciones();
            this.cdr.detectChanges();
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error al generar una cotización',
              life: 2000,
            });
          },
        });
    } 
  }
  }

  deleteCotization() {
      this.displayDelete = true;
      this.cdr.detectChanges();
      this.confirmationService.confirm({
        message: 'Deseas eliminar esta cotización?',
        header: 'Confirmar borrado',
        icon: 'pi pi-info-circle',
        accept: () => {
          // Filtramos el arreglo para obtener el punto que coincida con el ID proporcionado
          this.displayEdit =false;
          const ratioToDelete = this.cotizaciones.find((ratio) => ratio.pair_id.id === this.currency_id);

          if (ratioToDelete) {
            const index = this.cotizaciones.indexOf(ratioToDelete);
            if (index !== -1) {
              this.cotizaciones.splice(index, 1);
                // Forzar la actualización de la tabla
              this.cdr.detectChanges();
              this.apiService
                .delete(ApiService.apiURLBack + 'ratio', this.currency_id)
                .subscribe(
                  () => {
                    this.messageService.add({
                      severity: 'info',
                      summary: 'Confirmado',
                      detail: 'Registro eliminado',
                    });
                    this.getCotizaciones();
                  },
                  (error) => {
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: 'Error al eliminar el registro',
                    });
                  }
                );
            }
          } else {
            this.displayEdit = false;
            this.displayBasic = false;
            this.messageService.add({
              severity: 'warn',
              summary: 'Cotizacion no encontrada',
              detail: 'No se encontró la cotizacion a eliminar',
            });
          }
        },
      });

  }

  editCotizacion(cotizacion: any) {
    this.ratio_id = cotizacion.id;
    this.displayEdit =true;
    this.currency_id = cotizacion.pair_id.id;
    this.selectedCurrency =  this.currencies.find((n: { name: any; }) => n.name = cotizacion.pair_id.name);
   

    this.cotizacionRequest.pair_id = this.currency_id;
    this.cotizacion.pair_id = cotizacion.pair_id.id;
    this.cotizacion.mount_buy = cotizacion.mount_buy;
    this.cotizacion.mount_sell = cotizacion.mount_sell;
    console.log(this.cotizacion);
  }

formatCurrency(value: number): string {
  const formattedValue = value.toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 4,
  });

  // Eliminar los ceros y el punto decimal si están todos seguidos después de la coma
  return formattedValue.replace(/\.?0+$/, '').replace(/\.0$/, '').replace('ARS', '');
}

  onCurrencySelected(event:any) {
    if (!event.value) {
      this.currency_id = undefined;
    }
    else {
      this.currency_id = event.value.id;
    }
  }

  formatNumber(value: number): string {
    return this.numberFormatService.formatNumber(value);
  }
}
