import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FIELDS_TABLE_CURRENCY } from '../model/mocks/mock-fieldsTableCurrency';
import { Currency } from '../model/Currency';
import { CURRENCIES } from '../model/mocks/mock-currency';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ApiService } from '../services/api-service/apiService/api.service';
import { AuthService } from '../auth/guards/auth-service.service';

@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrenciesComponent implements OnInit {

  fieldsTableCurrencies  = FIELDS_TABLE_CURRENCY;
  currencies !: Currency[];
  selectedCurrency !: any;
  displayBasic!: boolean;
  displayDelete!:boolean;
  displayEdit!:boolean;
  selectedId!:any;
  newCurrency!: { name: string; symbol: string; };
  user!:any;
  canEdit: boolean = false;
  role: any;

  constructor(    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private authService: AuthService
  ) {

    this.user = this.authService.getCurrentUser();
    this.role = this.authService.userRole;
    if (this.user && this.user.permissions && this.user.permissions['configuracion']) {
      this.canEdit = this.user.permissions['configuracion'].includes('divisas_editar') || this.role == 'Administrador';
    }
  }

  ngOnInit(): void {
      this.getCurrencies();
  }

  getCurrencies(){
    this.apiService.get(ApiService.apiURLBack + 'divisa').subscribe({
      next: (response: any) => {
        this.currencies = response;
            // Forzar la actualización de la tabla
    this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  redirectTo(i: number) {

  }
  addNewCurrency() {
    this.newCurrency = {
      name: '',
      symbol: '',
    };
    this.displayBasic = true;
  }

  saveNewCurrency(){
    this.apiService
    .post(ApiService.apiURLBack + 'divisa', this.newCurrency)
    .subscribe({
      next: (response: any) => {
        this.currencies.push(response);

        this.displayBasic = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Divisa agregada exitosamente',
          life: 2000,
        });
        this.getCurrencies();
        this.cdr.detectChanges();
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error al agregar divisa',
          life: 2000,
        });
      },
    });
  }



  edit(currency:any) {
    this.displayEdit = true;
    this.newCurrency = {
      name: currency.name,
      symbol: currency.symbol
    };
    this.selectedId = currency.id;
  }

  saveEditCurrency(){

    this.apiService.put(ApiService.apiURLBack + 'divisa',this.selectedId,this.newCurrency)
    .subscribe(
      () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Registro editado',
        });
        this.getCurrencies();
        this.cdr.detectChanges();
        this.displayEdit =false;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al editar el registro',
        });
      }
    );
  }

  deleted(id:number) {
      this.displayDelete = true;
      this.cdr.detectChanges();
      this.confirmationService.confirm({
        message: 'Deseas eliminar esta divisa?',
        header: 'Confirmar borrado',
        icon: 'pi pi-info-circle',
        accept: () => {
          const currencyToDelete = this.currencies.find((user) => user.id === id);

          if (currencyToDelete) {
            const index = this.currencies.indexOf(currencyToDelete);
            if (index !== -1) {
              this.currencies.splice(index, 1);

                // Forzar la actualización de la tabla
              this.cdr.detectChanges();
              this.apiService
                .delete(ApiService.apiURLBack + 'divisa', id)
                .subscribe(
                  () => {
                    this.messageService.add({
                      severity: 'info',
                      summary: 'Confirmado',
                      detail: 'Registro eliminado',
                    });
                    this.getCurrencies();
                  },
                  (error) => {
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: 'Error al eliminar el registro',
                    });
                  }
                );
            }
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Divisa no encontrado',
              detail: 'No se encontró la divisa a eliminar',
            });
          }
        },
      });

  }
}
