import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberFormat]'
})
export class NumberFormatDirective {

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Quita los puntos existentes y reemplaza las comas por puntos
    const unformattedValue = value.replace(/\./g, '').replace(',', '.');
    const formattedValue = this.formatNumber(unformattedValue);

    // Actualiza el valor del control
    this.control.control?.setValue(formattedValue, {
      emitEvent: false, // Evita un bucle infinito de cambios
      emitModelToViewChange: true,
      emitViewToModelChange: true
    });

    // Establece el cursor al final del valor formateado
    this.el.nativeElement.selectionStart = this.el.nativeElement.selectionEnd = formattedValue.length;
  }

  private formatNumber(value: string): string {
    // Divide la parte entera y decimal
    const [integerPart, decimalPart] = value.split('.');

    // Formatea la parte entera con separadores de miles
    let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Limita la parte decimal a dos dígitos si existe
    if (decimalPart) {
      return `${formattedIntegerPart},${decimalPart.substring(0, 2)}`;
    } else {
      return formattedIntegerPart;
    }
  }
}