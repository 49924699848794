import { PasswordModule } from 'primeng/password';
import { LoginCredentials } from './../model/login-credentials.interface';
import { ApiService } from '../services/api-service/apiService/api.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../model/User';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth/guards/auth-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'ako-user-selector',
  templateUrl: './loggin.component.html',
  styleUrls: ['./loggin.component.css'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogginComponent implements OnInit{

  processingRequest = false;

  email: string = '';
  password: string = '';
  loginCredentials!: LoginCredentials;
  form = new FormGroup({
    username: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    password: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });
  formFieldsCompleted!: boolean;

  constructor(private messageService: MessageService, private authService: AuthService,
    private cdr: ChangeDetectorRef, private apiService: ApiService, private router: Router, private loaderService: LoaderService) { }
  ngOnInit(): void {
  }

  login() {
    this.checkFormFields();
    if(!this.formFieldsCompleted){
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Completa todos los campos del formulario antes de continuar',
        life: 2000,
      });
    }
    else {
      this.loaderService.updateIsLoading(true);
      this.postLogIn()
    }
  }

  postLogIn() {
    this.processingRequest = true;
    const userObject = {
     email : this.form.value.username,
     password : this.form.value.password
    }
    this.authService.login( ApiService.apiURLBack , userObject as LoginCredentials).subscribe({
       next: (response: any) => {
       this.authService.handleLoginResponse(response);
       this.processingRequest = true;
       this.loaderService.updateIsLoading(false);
       },
       error: (error: { message: any }) => {
         this.messageService.add({
           severity: 'error',
           summary: 'Credenciales Invalidas',
           life: 3000,
         });
         this.processingRequest = false;
       },
     });
  }

  checkFormFields() {
    if (this.form.value.username != undefined && this.form.value.password != undefined && this.form.value.username != '' && this.form.value.password!='') {
      this.formFieldsCompleted = true;
    } else {
      this.formFieldsCompleted = false;
    }
  }

  handleUnauthorized() {
    this.form.setErrors({ invalidCredentials: true });
    this.cdr.markForCheck();
  }
}
