import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FIELDS_TABLE_ROLE } from '../model/mocks/mock-fieldsTableRoles';
import { Role } from '../model/Role';
import { ROLES } from '../model/mocks/mock-roles';
import { ApiService } from '../services/api-service/apiService/api.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataStoreService } from '../services/dataStore/dataStoreService';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesComponent implements OnInit {

  fieldsTableRoles = FIELDS_TABLE_ROLE;
  roles !: Role[];
  selectedUser !: any;
  constructor(    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private dataStoreService: DataStoreService) { }

  ngOnInit(): void {
      this.getRoles();
  }

  getRoles(){
     this.apiService.get(ApiService.apiURLBack + 'rol').subscribe({
      next: (response: any) => {
        this.roles = response;
    this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }
  redirectTo(i: number) {
  }
  addNewRole() {

  }

  editRole() {

  }

  deleteRole() {

  }
}
