<p-toast></p-toast>
<title title="Configuracion  >  Usuarios"></title>
<div class="global-main-big-table" *ngIf="boxes">

  <div class="container-table">
    <div class="filters">
      <div>
          <span class="p-input-icon-left">
              <i class="pi pi-search" style="font-size: 18px; color: #797979;"></i>
              <input pInputText type="text" (input)="dtboxes.filterGlobal($any($event.target).value, 'contains')" placeholder="Filtrar por:" />
          </span>
      </div>
      <div>
          <span class="p-input-icon-left">
              <i class="pi pi-calendar" style="font-size: 18px; color: #797979; z-index:2;"></i>
              <p-calendar
                  style="margin-left: -1rem; margin-right: 1rem;"
                  styleClass="campo2"
                  [(ngModel)]="rangeDates"
                  selectionMode="range"
                  [readonlyInput]="true"
                  placeholder="Fecha/s"
                  [maxDate]="dateTime"
                  [icon]="calendarIcon"
                  [dateFormat]="'dd-mm-yy'"
                  (onSelect)="applyDateFilter()">
                  >
              </p-calendar>
          </span>
      </div>
      <div *ngIf="isFilter">
      <span class="icon">
        <p-button icon="pi pi-filter-slash" styleClass="p-button-filter" (click)="clearFilter()" class="p-button-text"></p-button>
      </span>
    </div>
  </div>


    <p-table
    #dtboxes
    dataKey="id"
    [sortField]="'date'"
    [sortOrder]="-1"
    [value]="isFilter ? filteredData : boxes"
    [tableStyle]="{ 'min-width': '25rem', 'margin-top': '1.5%' }"
    [scrollable]="true"
    scrollHeight="400px" 
    [paginator]="true"
    [rows]="50" 
    [rowsPerPageOptions]="[50, 100]"
    [globalFilterFields]="['currency_id.name','mount','point_of_sell.user.name']"
    autocomplete="off"
    class="global-table"
  >

    <ng-template pTemplate="header">
      <tr>
        <tr>
          <th class="p-d-flex p-jc-between p-ai-center">
            Fecha
        </th>
			<th class="p-d-flex p-jc-between p-ai-center">
					Divisas
			</th>
            <th class="p-d-flex p-jc-between p-ai-center">
                Estado
           </th>
			<th class="p-d-flex p-jc-between p-ai-center">
					Cantidad
			</th>
			<th class="p-d-flex p-jc-between p-ai-center">
					Caja
			</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-box let-i="rowIndex">
      <tr>
        <td>{{ formatDateOfViuwToString(box.date)}}</td>
        <td>{{ box.currency_id.name }}</td>
        <td>{{ box.status }}</td>
        <td>{{ formatNumber(box.mount) }}</td>
        <td>{{ box.point_of_sell.user.name }}</td>
      </tr>
    </ng-template>
  </p-table>

</div>

<div *ngIf="displayEgress">
  <p-dialog
    header="Quitar Monto"
    [(visible)]="displayEgress"
    [style]="{ width: '26vw'}"
    class="global-dialog-add"
    showEffect="fade"
  >
    <!-- ... Otros campos de entrada para el nuevo punto ... -->
    <div class="inputs">
      <div class="labelInput">
        <label for="name">Cantidad</label>
        <input tabindex="2" type="number" name="mount" required mount [(ngModel)]="mountEgress" #mount="ngModel" pInputText step="any" pattern="^\d{1,14}(?:\.\d{1,5})?$" id="mount" placeholder="Escriba una cantidad">
      <div [hidden]="mount.valid || !mount.touched" class="errors">
        <div>
          <span *ngIf="mount.errors?.['required']">
            Indique una cantidad
          </span>
        </div>
        <div>
          <span *ngIf="mount.errors?.['pattern']">
            Debe tener hasta 5 decimales y máximo de 14 dígitos.
          </span>
        </div>
      </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button class="global-button-add" icon="pi pi-save" (click)="saveEgress()" label="Guardar"></p-button>
    </ng-template>
  </p-dialog>
</div>

<div *ngIf="displayEntry">
  <p-dialog
    header="Ingresar Monto"
    [(visible)]="displayEntry"
    [style]="{ width: '26vw'}"
    class="global-dialog-add"
    showEffect="fade"
    [draggable]="false"
  >
    <!-- ... Otros campos de entrada ... -->
    <div class="inputs">
      <div class="labelInput">
        <label for="name">Cantidad</label>
        <input tabindex="2" type="number" name="mount" required mount [(ngModel)]="mountEntry" #mount="ngModel" pInputText step="any" pattern="^\d{1,14}(?:\.\d{1,5})?$" id="mount" placeholder="Escriba una cantidad">
      <div [hidden]="mount.valid || !mount.touched" class="errors">
        <div>
          <span *ngIf="mount.errors?.['required']">
            Indique una cantidad
          </span>
        </div>
        <div>
          <span *ngIf="mount.errors?.['pattern']">
            Debe tener hasta 5 decimales y máximo de 14 dígitos.
          </span>
        </div>
      </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button class="global-button-add" icon="pi pi-save" (click)="saveEntry()" label="Guardar"></p-button>
    </ng-template>
  </p-dialog>
</div>

</div>
