
  <!-- Content Header (Page header) -->
   <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 style="margin-top: 0.3rem;">{{titulo}}</h1>
        </div>
        <div class="col-sm-6" style="margin-top: 0.5rem;">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">Dashboard</li>
            <li *ngIf="titulo !== 'Dashboard'" class="breadcrumb-item">{{titulo}}</li>
            <li *ngIf="titulo === 'Dashboard'" class="breadcrumb-item"></li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
