import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { DataStoreService } from '../services/dataStore/dataStoreService';
import { FIELDS_TABLE_ROLE } from '../model/mocks/mock-fieldsTableRoles';
import { Role } from '../model/Role';
import { SidebarService } from '../services/sidebar.service';
import { BackendData, SubmenuItem } from '../model/BackendData'; 
import { Router } from '@angular/router';
import { AuthService } from '../auth/guards/auth-service.service';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermissionComponent implements OnInit {

  selectedUser !: any;
  fieldsTableRoles = FIELDS_TABLE_ROLE;
  roles !: Role[];
  modules = ['true','true','true','false','true'];
  isFilter = false;
  checked: any;
  selectedRol: any;
  modules2:any;
  permissionsKey: any = {}; 

  menuItems!: any[];
  role!:any;

  backendData: BackendData = {
    caja: [],
    dashboard: [],
    cotizaciones: [],
    configuracion: [],
    transacciones: [],
  };
  permissions: any;

  constructor(    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private dataStoreService: DataStoreService, 
    private sideBarServices: SidebarService,
    private router: Router
    ) { 
      this.menuItems = this.sideBarServices.menu;
     
    }

    ngOnInit(): void {
      if(this.selectedRol==null) {
        this.getRoles();
      }
  }

  getRoles(){
     this.apiService.get(ApiService.apiURLBack + 'rolp').subscribe({
      next: (response: any) => {
        this.roles = response;
       if (!this.selectedRol) {
        this.selectedRol = this.roles[1];
      }
        // Convierte la cadena JSON a un objeto JavaScript.
        this.permissions = JSON.parse(response[1].permissions);
     
        this.initializeSwitches();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }
  
  initializeSwitches(): void {
    this.menuItems.forEach(item => {
        const itemTitleNormalized = this.normalizeTitle(item.title);
        const isActive = this.permissions.hasOwnProperty(itemTitleNormalized);
        item.activo = isActive;

        if (isActive) {
            item.submenu.forEach((subitem: any) => { 
                const subitemTitleNormalized = this.normalizeTitle(subitem.title);
                subitem.activo = this.permissions[itemTitleNormalized].includes(subitemTitleNormalized);
                const editPermission = subitemTitleNormalized + '_editar';
                subitem.editable = this.permissions[itemTitleNormalized].includes(editPermission);
            });
        } else {
            item.submenu.forEach((subitem: any) => { 
                subitem.activo = false;
            });
        }
    });

  }

  normalizeTitle(title: string): string {
    // Convertir el título a minúsculas y eliminar las tildes
    return title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  onRolSelected($event : any) {
    this.apiService.getById(ApiService.apiURLBack + 'rol', $event.value.id).subscribe({
      next: (response: any) => {
        // Convierte la cadena JSON a un objeto JavaScript. le saca las barritas
        this.permissions = JSON.parse(response.permissions);
        console.log(this.permissions);
        this.initializeSwitches();
        console.log(this.permissions);

        this.selectedRol = $event.value;
  
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  clearFilter() {
    this.isFilter = false;
  }

  savePermissions() {
    this.updatePermissionsToBackend();
    this.router.navigateByUrl('/pages/dashboard');
  }

  showModules(module: any) {
    this.updatePermissions(module);
  }
  
  showModules2(module: any, submodule: any) {
    if (module && module.submenu && submodule) {
      this.updatePermissions(module, submodule);
    } else {
    }
  }

   updatePermissionsToBackend(){
    const payload = { permissions: this.permissions };
    console.log(this.permissions);
    this.apiService.put(ApiService.apiURLBack + 'rol',this.selectedRol.id, payload).subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Confirmado',
            detail: 'Cambios guardados',
          });
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al guardar cambios',
          });
        }
      });
  }

  updatePermissions(module: any, submodule?: any) {
    const moduleTitleNormalized: string = this.normalizeTitle(module.title);
    // Inicializar el arreglo si no existe
    this.permissions[moduleTitleNormalized] = this.permissions[moduleTitleNormalized] || [];
    
    if (submodule) {
      const subitemTitleNormalized: string = this.normalizeTitle(submodule.title);
      // Agregar o eliminar el subítem según su estado activo
      submodule.activo 
        ? this.permissions[moduleTitleNormalized].push(subitemTitleNormalized) 
        : this.permissions[moduleTitleNormalized] = this.permissions[moduleTitleNormalized].filter((item: string) => item !== subitemTitleNormalized);
    } else {
      // Agregar o eliminar el módulo completo según su estado activo
      module.activo 
        ? this.permissions[moduleTitleNormalized] = [] 
        : delete this.permissions[moduleTitleNormalized];
    }



  }
  updateEditPermissions(module: any, submodule?: any) {
    const moduleTitleNormalized: string = this.normalizeTitle(module.title);
    this.permissions[moduleTitleNormalized] = this.permissions[moduleTitleNormalized] || [];

    if (submodule) {
        const subitemTitleNormalized: string = this.normalizeTitle(submodule.title);
        const editPermission = subitemTitleNormalized + "_editar";

        if (submodule.editable) {
            if (this.permissions[moduleTitleNormalized].indexOf(subitemTitleNormalized) === -1) {
                this.permissions[moduleTitleNormalized].push(subitemTitleNormalized);
            }

            if (this.permissions[moduleTitleNormalized].indexOf(editPermission) === -1) {
                this.permissions[moduleTitleNormalized].push(editPermission);
            }
        } else {
            this.permissions[moduleTitleNormalized] = this.permissions[moduleTitleNormalized]?.filter((item: string) => {
                return item !== editPermission;
            });
        }

    }
}
}
