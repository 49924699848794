<p-toast></p-toast>
<title title="Configuración  >  Usuarios"></title>
<div class="global-main-table" *ngIf="clients">
  <p-button *ngIf="canEdit" class="global-button-add" iconPos="left" (onClick)="addNewClient()"> Agregar </p-button>
  <p-table
    id="tableUser"
    #dtclients
    [value]="clients"
    [tableStyle]="{ 'min-width': '25rem' , 'margin-top':'1.5%' }"
    [paginator]="true"
    [rows]="6"
    [rowsPerPageOptions]="[1, 2, 3, 4 , 5 , 6]"
    selectionMode="single"
    autocomplete="off"
    class="global-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Nombre</th>
        <th *ngIf="canEdit">Acciones</th>      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-client let-i="rowIndex">
      <tr>
        <td>{{ client.name }}</td>
        <td *ngIf="canEdit">
          <div class="optionsButtons" >
            <button pButton (click)="edit(client)" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-plain" ></button>
            <button pButton (click)="deleted(client.id)"  icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
        </div>
      </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="card flex justify-content-center gap-2" *ngIf="displayDelete">
  <p-confirmDialog #cd [style]="{width: '50vw'}">
    <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" ></button>
      <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" ></button>
  </ng-template>
  </p-confirmDialog>
</div>

<div *ngIf="displayBasic">
    <p-dialog
      header="Nuevo Cliente"
      [(visible)]="displayBasic"
      [style]="{ width: '28vw'}"
      [breakpoints]="{ '1782px': '30vw', '1559px': '35vw' , '1394px': '40vw' ,
      '1275px': '45vw','1138px': '50vw','1048px': '55vw', '960px': '60vw'  , '890px': '70vw'}"
      class="global-dialog-add"
      showEffect="fade"
    >
      <!-- ... Otros campos de entrada para el nuevo punto ... -->
      <div class="inputs">
        <div class="labelInput">
          <label for="name">Nombre</label>
          <input tabindex="1" type="text" id="name" name="name" required name pInputText [(ngModel)]="newClient.name" />
        </div>
    </div>
      <ng-template pTemplate="footer">
        <p-button class="global-button-add" icon="pi pi-save" (click)="saveClient()" label="Guardar"></p-button>
      </ng-template>
    </p-dialog>
  </div>

  <div *ngIf="displayEdit">
    <p-dialog
      header="Editar Cliente"
      [(visible)]="displayEdit"
      [style]="{ width: '28vw'}"
      [breakpoints]="{ '1782px': '30vw', '1559px': '35vw' , '1394px': '40vw' ,
      '1275px': '45vw','1138px': '50vw','1048px': '55vw', '960px': '60vw'  , '890px': '70vw'}"
      class="global-dialog-add"
      [draggable]="false"
    >
      <!-- ... Otros campos de entrada para el nuevo punto ... -->
      <div class="inputs">
        <div class="labelInput">
          <label for="name">Nombre</label>
          <input tabindex="1" type="text" id="name" name="name" required name pInputText [(ngModel)]="newClient.name" />
        </div>
      </div>
      <ng-template pTemplate="footer">
        <p-button class="global-button-add" icon="pi pi-save" (click)="saveEdit()" label="Guardar"></p-button>
      </ng-template>
    </p-dialog>
  </div>
