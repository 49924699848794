<p-toast position="top-center"></p-toast>


<div class="container-fluid d-flex align-items-center justify-content-center" style="height: 100vh; margin: 0;">
  <div class="row" >
    <div class="col-md-6 bg-white">
      <form [formGroup]="form" (ngSubmit)="login()" class="login-form" style="margin-top:1.5rem">
    
        <div class="icon d-flex align-items-center justify-content-center">
          <img src="assets/img/usuario.jpg" alt="" class="img-fluid" width="70px" height="70px" />
       
        </div>

        <div class="data-user d-flex flex-column align-items-center">
          <div class="labelInput"><label for="email">Email</label></div>
          <input tabindex="1" type="email" id="username" name="username" formControlName="username" minlength="8" required pInputText>

          <div class="labelInput">
            <label for="password">Contraseña</label>
          </div>
          <span>
          <p-password 
          [toggleMask]="true" 
          [feedback]="false"
          id="password" name="password" formControlName="password"
          >
        </p-password>
      </span>
          <p-button type="submit" [disabled]="form.invalid || processingRequest" class="button">Entrar</p-button>
        </div>
      </form>
    </div>

    <div class="col-md-6 d-none d-md-block">
      <div class="container-dataPhoto">
        <img src="assets/img/fondo_login.jpg" alt="" class="img-fluid"  style="width: auto;"/>
      </div>
    </div>
  </div>
</div>
