import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { FIELDS_TABLE_BOXSTATE } from '../model/mocks/mock-fieldsTableStateBox';
import { Table } from 'primeng/table';
import { NumberFormatService } from '../services/numberFormat/number-format-service.service';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';

export const customCurrencyMaskConfig2: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 3,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@Component({
  selector: 'app-box-status',
  templateUrl: './box-status.component.html',
  styleUrls: ['./box-status.component.css'],
  providers: [ConfirmationService, MessageService,
    { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig2 }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxStatusComponent implements OnInit {
  boxes!: any;
  displayEgress!:boolean;
  displayEntry!:boolean;
  mountEntry!:number;
  mountEntryRequest!:any;
  mountEgress!:number;
  mountEgressRequest!:any;
  newObjectEntry!:any;
  newObjectEgress!:any;
  selectedIDbox!:number;
  fieldsTableStateBox = FIELDS_TABLE_BOXSTATE;
  point_of_sell: any;
  @ViewChild('dtboxes') dtboxes: any; // Agrega esta línea para obtener la referencia al componente p-table
  items!: SelectItem[];
  item!: string;
  selectedCountries1: string[] = [];
  selectedCountries2: string[] = [];
  parsedNumber!: number;
  parsedNumber2!: number;

  constructor(    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,private numberFormatService: NumberFormatService
  ) {
    this.items = [];
  }

  ngOnInit(): void {
    this.mountEgress = 0;
    this.mountEntry = 0;
    this.mountEgressRequest= '0';
    this.mountEntryRequest = '0';
    this.getBoxes();
  }

  getBoxes(){
    this.apiService.get(ApiService.apiURLBack + 'caja').subscribe({
      next: (response: any) => {
        this.boxes = response;
        const uniqueNames = new Set<string>();
        this.point_of_sell = this.boxes
          .map((box: any) => ({
            id: box.point_of_sell.user.id,
            name: box.point_of_sell.user.name,
          }))
          .filter((item: any) => {
            if (!uniqueNames.has(item.name)) {
              uniqueNames.add(item.name);
              return true;
            }
            return false;
          });
        this.items = this.point_of_sell;
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  egressMount(box: any) {

    this.displayEgress =true;
    this.newObjectEgress = {
      currency_id : box.currency_id.id,
      mount : 0
    }
    this.selectedIDbox = box.point_of_sell.id;
  }

  entryMount(box: any) {
    this.displayEntry =true;
    this.newObjectEntry = {
      currency_id : box.currency_id.id,
      mount : 0
    }
    this.selectedIDbox = box.point_of_sell.id;
  }

  saveEgress() {
    const numberAsString = this.mountEgressRequest ?? '0';
    this.parsedNumber = parseFloat(numberAsString);
    this.mountEgress = this.parsedNumber;
    this.newObjectEgress.mount = this.mountEgress;
    this.apiService.post(ApiService.apiURLBack+ 'caja/' + this.selectedIDbox + '/egreso' ,this.newObjectEgress).subscribe({
      next: (response: any) => {
        this.displayEgress = false;
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Cambio confirmado',
        });
        this.clearData();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });

  }

  saveEntry(){
    const numberAsString = this.mountEntryRequest ?? '0';
    this.parsedNumber2 = parseFloat(numberAsString);
    this.mountEntry = this.parsedNumber2;

    this.newObjectEntry.mount = this.mountEntry;
   this.apiService.post(ApiService.apiURLBack+ 'caja/' + this.selectedIDbox + '/ingreso' ,this.newObjectEntry).subscribe({
      next: (response: any) => {
        this.displayEntry = false;
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Cambio confirmado',
        });
        this.clearData();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  clearData(){
    this.mountEgress = 0;
    this.mountEntry = 0;
    this.mountEgressRequest= '0';
    this.mountEntryRequest = '0';
    this.getBoxes();
  }


formatNumber(value: number): string {
  return this.numberFormatService.formatNumber(value);
}
}
