export const FIELDS_TABLE_TRANSACTION_PENDIENT = [
  {
    title: 'Fecha',
    field: 'date',
    type: 'text',
  },
  {
    title: 'Transacción',
    field: 'name',
    type: 'text',
  },
  {
    title: 'Divisas',
    field: 'pair_id',
    type: 'text',
  },
  {
    title: 'Cantidad',
    field: 'name',
    type: 'number',
  },
  {
    title: 'Precio',
    field: 'name',
    type: 'number',
  },
  {
    title: 'Monto',
    field: 'name',
    type: 'number',
  },
  {
    title: 'Cliente',
    field: 'name',
    type: 'text',
  },
  {
    title: 'Acciones',
    field: 'actions',
    type: 'text',
  },
];
