export const FIELDS_TABLE_PAR = [

      {
        title: "Nombre",
        field: "name",
        type: "text"
      },
      {
        title: "Acciones",
        field: "actions",
        type: "text"
      }
    ]
  