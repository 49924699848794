<app-loader></app-loader>

<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4" >
  <!-- Brand Logo -->
  <a class="brand-link">
    <img src="./assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
    <span class="brand-text font-weight-light"> Admin </span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar" *ngIf="role">

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item" *ngFor="let item of menuItems">
          <a [routerLink]="item.routerLink" class="nav-link">
            <i class="{{ item.icon }}"></i>
            <p class="ml-2">
              {{ item.title }}
              <i *ngIf="item.submenu && item.submenu.length > 0" class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngFor="let submenu of item.submenu">
              <a [routerLink]="submenu.routerLink" class="nav-link submenu-item" style="cursor:pointer;">
                <p class="ml-5 submenu-item">{{ submenu.title }}</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
  <!-- /.sidebar -->
</aside>
