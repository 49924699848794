

<app-loader></app-loader>
<div class="container-fluid" style="margin-left: 0rem">


    <div class="filters">
              <span class="p-input-icon-left">
                <div class="flex">
                  <div>
                    <p-dropdown
                      [options]="par"
                      [(ngModel)]="selectedPar"
                      placeholder="Seleccionar"
                      optionLabel="name"
                      [showClear]="true"
                      (onChange)="onParSelected($event)"
                    ></p-dropdown>
                  </div>
                </div>
              </span>

              <span class="p-input-icon-left">
              <p-multiSelect [options]="users" placeholder="Seleccionar" (onChange)="onUsersSelected($event)" [(ngModel)]="selectedUsers" optionLabel="name"></p-multiSelect>
            </span>

              <span class="p-input-icon-left">
                  <i class="pi pi-calendar" style="font-size: 18px; color: #797979; z-index:1;"></i>
                  <p-calendar
                      style="margin-right: 1rem;"
                      styleClass="campo2"
                      [(ngModel)]="rangeDates"
                      selectionMode="range"
                      [readonlyInput]="true"
                      placeholder="Fecha/s"
                      [maxDate]="dateTime"
                      [icon]="calendarIcon"
                      [dateFormat]="'dd-mm-yy'"
                      (onSelect)="applyDateFilter()">
                      >
                  </p-calendar>
              </span>
      
          <div *ngIf="isFilter">
          <span class="icon">
            <p-button icon="pi pi-filter-slash" styleClass="p-button-filter" (click)="clearFilter()" class="p-button-text"></p-button>
          </span>
        </div>

      </div>
      
 <!-- Tarjetas -->

      <div class="row" style="width: 100%; margin-left: 0.3rem;" *ngIf="dashboardResponse">

        <div class="col-lg-4 col-md-12" >
          <div class="row">
              <div class="col-6">
                  <div class="small-box bg-info" style="background-color: #707dbf; min-height: 100px;  margin-top: 1rem;">
                      <div class="inner" style="background-color: #707dbf; min-height: 110px;">
                          <h3 >  {{ dashboardResponse.cards['box$'][0].mount }}</h3>
                          <p >{{ dashboardResponse.cards['box$'][0].name }}</p>
                      </div>
                  </div>
              </div>
  
              <div class="col-6">
                  <div class="small-box bg-success" style="background-color: #7288ff; min-height: 100px; margin-top: 1rem;">
                      <div class="inner" style="background-color: #7288ff; min-height: 110px;">
                        <h3 >  {{ dashboardResponse.cards['box$'][1].mount }}</h3>
                        <p>{{ dashboardResponse.cards['box$'][1].name }}</p>
                      </div>
                  </div>
              </div>

              <div class="col-6">
                <div class="small-box bg-success" style="background-color: #7288ff; min-height: 100px;">
                    <div class="inner" style="background-color: #7288ff; min-height: 110px;">
                      <h3 >  {{ dashboardResponse.cards['box$'][2].mount }}</h3>
                      <p >{{ dashboardResponse.cards['box$'][2].name }}</p>
                    </div>
                </div>
            </div>
        
            <div class="col-6">
              <div class="small-box bg-success" style="background-color: #7288ff; min-height: 100px;">
                  <div class="inner" style="background-color: #7288ff; min-height: 110px;">
                    <h3>  {{ dashboardResponse.cards['box$'][3].mount }}</h3>
                    <p>{{ dashboardResponse.cards['box$'][3].name }}</p>
                  </div>
              </div>
          </div>

          <div class="col-6">
            <div class="small-box bg-success" style="background-color: #7288ff; min-height: 100px;">
                <div class="inner" style="background-color: #7288ff; min-height: 110px;">
                  <h3>  {{ dashboardResponse.cards['box$'][4].mount }}</h3>
                  <p>{{ dashboardResponse.cards['box$'][4].name }}</p>
                </div>
            </div>
        </div>
        
        <div class="col-6">
                  <div class="small-box bg-success" style="background-color: #7288ff; min-height: 100px;">
                      <div class="inner" style="background-color: #7288ff; min-height: 110px;">
                        <h3>  {{ dashboardResponse.cards['box$'][5].mount }}</h3>
                        <p>{{ dashboardResponse.cards['box$'][5].name }}</p>
                      </div>
                  </div>
              </div>

              <div class="col-lg-8 col-10">
                <div class="small-box bg-info" style="background-color: #707dbf; min-height: 100px; margin-top: 1.5rem;">
                  <div class="inner" style="background-color: #707dbf; min-height: 110px;">
                    <h3 style="text-align: center;">{{ dashboardResponse.cards.transactionFinish }}</h3>
                    <p style="text-align: center;">Transacciones Finalizadas</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <a [routerLink]="'/pages/transacciones/historial'" class="small-box-footer" style="background-color: #707dbf">Mas Info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
              
              <div class="col-lg-8 col-8">
                <div class="small-box bg-success" style="background-color: #7288ff; min-height: 100px;">
                  <div class="inner" style="background-color: #7288ff; min-height: 110px;">
                    <h3 style="text-align: center;">{{ dashboardResponse.cards.transactionPending }}</h3>
                    <p style="text-align: center;">Transacciones Pendientes</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a [routerLink]="'/pages/transacciones/ver-pendientes'" class="small-box-footer" style="background-color: #7288ff">Mas Info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
        
              </div>


            </div>
          </div>
          
<!-- Tabla superior -->
<div class="col-lg-7 col-md-12"  style="margin-top: 0.8rem; ">
  <ng-container>
      <p-table [value]="isFilter ? filteredData : tableA" styleClass="global-table" [scrollable]="true" [scrollHeight]="'ml-auto'" [tableStyle]="{ 'min-width' : '30rem', 'min-height': '20rem' }" #dtmontos>
          <ng-template pTemplate="header">
              <tr>
                  <th></th>
                  <th></th>
                  <th>Compra</th>
                  <th>Venta</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-table let-rowIndex="rowIndex">
              <tr>
                  <td rowspan="7" colspan="1" style="background-color: #707dbf; color: white;">{{ table.name }}</td>
              </tr>
              <tr>
                  <td>{{ getCurrencyName(table.name) }}</td>
                  <td>{{ formatNumber(table.buy) }}</td>
                  <td>{{ formatNumber(table.sell) }}</td>
              </tr>
              <tr>
                  <td rowspan="1">{{ getCurrencyCode(table.name) }}</td>
                  <td>{{ formatNumber(table['buy$']) }}</td>
                  <td>{{ formatNumber(table['sell$']) }}</td>
              </tr>
              <tr>
                  <td rowspan="1">Promedio</td>
                  <td>{{ formatNumber(table.averagebuy) }}</td>
                  <td>{{ formatNumber(table.averagesell) }}</td>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
          </ng-template>
      </p-table>
  </ng-container>
  <!-- Tabla Inferior -->
<div class="row">
  <div class="col-lg-12 col-md-12" style="margin-top: 1rem; margin-left: 0rem;">
    <ng-container>
        <p-table [value]="isFilter ? filteredDataB : tableB" styleClass="global-table" [scrollable]="true" [scrollHeight]="'800px'" [tableStyle]="{ 'min-width' : '30rem', 'min-height': '320px'}" #dtmontos>
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th>Usuario</th>
                    <th>Compra</th>
                    <th>Venta</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-table let-rowIndex="rowIndex">
                <tr>
                    <td [attr.rowspan]="table.users.length + 1" colspan="1" style="background-color: #707dbf; color: white;">{{ table.name }}</td>
                </tr>
                <ng-container *ngFor="let user of table.users; let i = index">
                    <tr>
                        <td rowspan="1">{{ user.user }}</td>
                        <td>{{ user.buy }}</td>
                        <td>{{ user['sell$'] }}</td>
                    </tr>
                </ng-container>
            </ng-template>
        </p-table>
    </ng-container>
  </div>
  </div>
</div>
</div>


</div>