<p-toast></p-toast>
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7">
        <div class="card card-primary">
          <div class="card-header" style="background-color: #707dbf">
            <h3 class="card-title">Transacción</h3>
          </div>

          <form>
            <div class="card-body">
              <!-- Primera fila de campos -->
              <div class="row">
                <div class="col-6">
                  <label for="inputEmail3">Clientes</label>
                  <div>
                    <p-dropdown
                      requerid
                      class="p-dropdown-globalBox"
                      appendTo="body"
                      optionLabel="name"
                      [options]="clients"
                      [showClear]="true"
                      (onChange)="onClientSelected($event)"
                    ></p-dropdown>
                  </div>
                </div>

                <div class="col-6 ml-auto">
                  <label for="exampleInputEmail1">Tipo de transacción</label>
                  <div>
                    <p-dropdown
                      requerid
                      placeholder="Seleccionar"
                      class="p-dropdown-globalBox"
                      appendTo="body"
                      name="typeOfTransaction"
                      [(ngModel)]="selectedTypeOfTransaction"
                      optionLabel="name"
                      [options]="typesOfTransaction"
                      [showClear]="true"
                      (onChange)="onTypeTransactionSelected($event)"
                    ></p-dropdown>
                  </div>
                </div>
              </div>

              <!-- Segunda fila de campos -->
              <div class="row">
                <div class="col-6">
                  <label for="inputEmail3" class="col-form-label"
                    >Divisas</label
                  >
                  <div>
                    <p-dropdown
                      requerid
                      placeholder="Seleccionar"
                      class="p-dropdown-globalBox"
                      appendTo="body"
                      optionLabel="name"
                      name="pair"
                      [(ngModel)]="selectedPair"
                      [options]="par"
                      [showClear]="true"
                      (onChange)="onCurrencySelectedPar($event)"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-6 ml-auto">
                  <label for="quantity" class="col-form-label">Cantidad</label>
                  <div>
                    <input
                    tabindex="1"
                    type="text"
                    currencyMask
                    name="quantity"
                    [(ngModel)]="newTransaction.quantity"
                    quantity
                    pInputText
                    step="any"
                    inputmode="numeric"
                    maxlength="18"
                    id="quantity"
                    placeholder="Escriba una cantidad"
                    style="font-weight: 600"
                    />
                  </div>
                </div>
              </div>

              <!-- Tercera fila de campos -->
              <div class="row">
                <div class="col-6">
                  <label for="exampleInputPassword1">Precio</label>
                  <div>
                    <input
                      class="input-global"
                      currencyMask
                      style="font-weight: 600"
                      tabindex="2"
                      type="text"
                      name="price"
                      inputmode="numeric"
                      [(ngModel)]="newTransaction.price"
                      [disabled]="false"
                      price
                      pInputText
                      step="any"
                      id="price"
                      placeholder="Escriba un precio"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                style="
                  background-color: #707dbf;
                  border-color: #707dbf;
                  float: right;
                "
                (click)="generatingTransaction()"
              >
                Generar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
