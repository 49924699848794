import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { Router } from '@angular/router';
import { Client } from '../model/Client';
import { FIELDS_TABLE_CLIENT } from '../model/mocks/mock-fieldsTableClients';
import { AuthService } from '../auth/guards/auth-service.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsComponent implements OnInit {

  fieldsTableClient= FIELDS_TABLE_CLIENT;
  clients!: Client[];
  displayBasic!: boolean;
  displayDelete!: boolean;
  displayEdit!: boolean;
  selectClientId!: any;
  newClient!: any;

  user!:any;
  canEdit: boolean = false;
  role: any;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,  private authService: AuthService
  ) {

    this.user = this.authService.getCurrentUser();
    this.role = this.authService.userRole;
    if (this.user && this.user.permissions && this.user.permissions['configuracion']) {
      this.canEdit = this.user.permissions['configuracion'].includes('clientes_editar') || this.role == 'Administrador';
    }
  }

  ngOnInit(): void {
    this.getClients();
  }

  getClients(){
    this.apiService.get(ApiService.apiURLBack + 'cliente').subscribe({
      next: (response: any) => {
        this.clients = response;

    this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }


  deleted(id:number){
    this.displayDelete = true;
    this.cdr.detectChanges();
    this.confirmationService.confirm({
      message: 'Deseas eliminar este cliente?',
      header: 'Confirmar borrado',
      icon: 'pi pi-info-circle',
      accept: () => {

            this.apiService
              .delete(ApiService.apiURLBack + 'cliente', id)
              .subscribe(
                () => {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Confirmado',
                    detail: 'Registro eliminado',
                  });
              this.displayDelete = false;
              this.getClients();
              this.cdr.detectChanges();
                },
                (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error al eliminar el registro',
                  });
                }
              );
      },
    });
  }

  edit(client:any) {
    this.displayEdit = true;
    this.newClient = {
      name: client.name,
    };
    this.selectClientId = client.id;

    }

    saveEdit() {
        this.apiService.put(ApiService.apiURLBack + 'cliente', this.selectClientId, this.newClient)
        .subscribe(
          () => {
            this.messageService.add({
              severity: 'info',
              summary: 'Confirmado',
              detail: 'Registro editado',
            });
            this.getClients();
            this.cdr.detectChanges();
            this.displayEdit =false;
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al editar el registro',
            });
          }
        );

    }

  addNewClient() {
    this.newClient= {
      name: '',
    };
    this.displayBasic = true;
  }

  saveClient(){
    this.apiService
    .post(ApiService.apiURLBack + 'cliente', this.newClient)
    .subscribe({
      next: (response: any) => {
        this.clients.push(response);
        this.displayBasic = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Cliente agregado exitosamente',
          life: 2000,
        });
        this.getClients();
        this.cdr.detectChanges();
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error al agregar el cliente',
          life: 2000,
        });
      },
    });

  }

}
