import { filter } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { AuthService } from '../auth/guards/auth-service.service';
import { ApiService } from '../services/api-service/apiService/api.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { NumberFormatService } from '../services/numberFormat/number-format-service.service';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {

  isLoggedIn: boolean = false;
  currentUser$ = this.authService.user$;
  par: any;
  selectedPar!: any;
  selectedParId!: number;
  selectedMonto: any;
  selectedFirstMonto: any;
  @ViewChild('dtmontos') dtmontos!: Table;
  isFirstTable = false;

  dashboardResponse!: any;
  startDate: any;
  endDate: any;
  calendarIcon = 'pi pi-calendar';
  filteredData: any[] = [];
  isFilter = false;
  rangeDates: Date[] | undefined;
  records: any;
  dateTime = new Date();
  value: string | undefined;
  indicePar: any;
  tableBIndicePar: any;
  tableAIndicePar: any;
  tableA: any;
  tableB: any;
  repetitions: number = 0;
  namesTableB: any;
  filteredDataB: any;
  users!: any[];
  selectedUsers: any;
  selectedIds: any;
  dashboardInit!:any;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private numberFormatService: NumberFormatService, private loaderService:LoaderService
  ) {
    this.dateTime.setDate(this.dateTime.getDate());
  }

  ngOnInit(): void {
    this.selectedPar = undefined;
    this.loaderService.updateIsLoading(true);
    this.getPar();
    this.dateTime.setDate(this.dateTime.getDate());

    const currentDate = new Date();
    this.tableBIndicePar = 0;
    this.rangeDates = [currentDate, currentDate];
    this.getUsers();
  }

  getUsers(){
    this.apiService.get(ApiService.apiURLBack + 'usuario').subscribe({
      next: (usersResponse: any) => {
        this.users = usersResponse; 

        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  clear(table: Table) {
    table.clear();
  }

  onUsersSelected(event: any) {
    if (event.value === null) {
      // User cleared the selection, reset the filtering
      this.clearFilter();
      return;
    }
    this.selectedIds = event.value.map((user: any) => user.id);
    
    this.getDashboardFilter();
    }

  onParSelected(event: any) {
    if (event.value === null) {
      this.clearFilter();
      return;
    }

    this.cdr.detectChanges();
    this.selectedPar.id = event.value.id;
    const selectedParName = event.value.name;
    this.tableAIndicePar = this.dashboardInit.tableA.findIndex(
      (par: { name: any }) => par.name === selectedParName
    );
    this.tableBIndicePar = this.dashboardInit.tableB.findIndex(
      (par: { name: any }) => par.name === selectedParName
    );

    this.getDashboardFilter();
  }

  clearFilter() {
    this.isFilter = false;
    this.filteredData = this.tableA;
    this.filteredDataB = this.tableB;
    this.selectedIds = null;
    this.selectedPar = null;
    this.selectedUsers = null;
    const currentDate = new Date();
    this.rangeDates = [currentDate, currentDate];
    this.startDate = this.formatDate(currentDate);
    this.endDate = this.formatDate(currentDate);
    this.getDashboard();
  }

  getDashboardFilter() {

      this.isFilter = true;

      if (this.startDate === undefined || this.endDate === undefined) {
        const currentDate = new Date();

        this.startDate = this.formatDate(currentDate);
        this.endDate = this.formatDate(currentDate);
      }


      if(this.selectedPar != undefined) {
        this.apiService
        .getDashboard(this.selectedPar.id, this.startDate, this.endDate, this.selectedIds)
        .subscribe({
          next: (response: any) => {
            this.dashboardResponse = response;
            this.filteredData = [response.tableA];
            this.filteredDataB = [response.tableB];
            this.cdr.detectChanges();
          },
          error: (error: { message: any }) => {
            this.messageService.add({
              severity: 'error',
              summary: error.message,
              life: 2000,
            });
          },
        });
      }
      else {

        if(this.selectedPar == undefined && this.selectedIds!=undefined){
          this.apiService
          .getDashboard2(this.startDate, this.endDate, this.selectedIds)
          .subscribe({
            next: (response: any) => {
              this.filteredData = response.tableA;
              this.filteredDataB = response.tableB;
            
              this.cdr.detectChanges();
            },
            error: (error: { message: any }) => {
              this.messageService.add({
                severity: 'error',
                summary: error.message,
                life: 2000,
              });
            },
          });
        
        }
        else {

          this.apiService
          .getDashboard3(this.startDate, this.endDate)
          .subscribe({
            next: (response: any) => {
              this.dashboardResponse = response;
              this.filteredData = response.tableA;
              this.filteredDataB = response.tableB;
        
              this.cdr.detectChanges();
            },
            error: (error: { message: any }) => {
              this.messageService.add({
                severity: 'error',
                summary: error.message,
                life: 2000,
              });
            },
          });
        }
      }
      
     
    
  }

  getPar() {
    this.apiService.get(ApiService.apiURLBack + 'par').subscribe({
      next: (response: any) => {
        this.par = response;
        this.cdr.detectChanges();
        this.getDashboard();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getDashboard() {

    if (this.startDate === undefined || this.endDate === undefined) {
      const currentDate = new Date();

      this.startDate = this.formatDate(currentDate);
      this.endDate = this.formatDate(currentDate);
    }
    this.apiService.getDashboard2(this.startDate, this.endDate).subscribe({
      next: (response: any) => {
        this.dashboardResponse = response;
        this.dashboardInit = response;
        this.tableA = this.dashboardResponse.tableA;
        this.tableB = this.dashboardResponse.tableB;
        this.loaderService.updateIsLoading(false);
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
        this.loaderService.updateIsLoading(false);
      },
    });
  }

  getCurrencyName(fullName: string): string {
    const index = fullName.indexOf('/');
    return index !== -1 ? fullName.substring(0, index).trim() : fullName;
  }

  getCurrencyCode(fullName: string): string {
    const index = fullName.indexOf('/');
    return index !== -1 ? fullName.substring(index + 1).trim() : fullName;
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  calculateRepetitions(table: any): number {
    this.repetitions = Math.ceil(table.users.length / 3);
    return this.repetitions;
  }

  applyDateFilter() {
    if (this.rangeDates && this.rangeDates[0] && this.rangeDates[1]) {
      this.isFilter = true;
      this.startDate = this.formatDate(this.rangeDates[0]);
      this.endDate = this.formatDate(this.rangeDates[1]);

      this.getDashboardFilter();
  } else {
      this.isFilter = false; // Seleccionar todo
      this.filteredData = this.tableA; // Restaurar los datos originales
      this.filteredDataB = this.tableB;
  }
  }

  actualizarDatosTabla() {
    this.dtmontos.reset(); // Borra la tabla
    this.dtmontos.clear();
    this.cdr.detectChanges(); // Detecta cambios para actualizar la tabla con los nuevos datos
  }

  formatNumber(value: number): string {
    return this.numberFormatService.formatNumber(value);
  }
}
