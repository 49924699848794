import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/auth/guards/auth-service.service';

import { SidebarService } from 'src/app/services/sidebar.service';

import { ApiService } from 'src/app/services/api-service/apiService/api.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-sidevar',
  templateUrl: './sidevar.component.html',
  styleUrls: ['./sidevar.component.css']
})
export class SidevarComponent implements OnInit{

  menuItems!: any[];
  role!:any;
  roles !: any[];
  selectedRol: any;
  permissions: any;
  
  constructor(private sideBarServices: SidebarService, private authService: AuthService, private apiService: ApiService, private loaderService:LoaderService){
    this.role = this.authService.rolId;
  }

  ngOnInit(): void {
    this.loaderService.updateIsLoading(true);
    console.log(this.role);
    if (this.isAdmin()) {
      console.log('Usuario administrador detectado. Mostrando todo el menú.');
      this.menuItems = this.sideBarServices.menu;
      this.loaderService.updateIsLoading(false);
      return;
    }
   
    this.getRoles();
  }

  filterMenuItemsByPermissions(): void {
    if (!this.permissions) return;
  
    const filteredMenuItems: any[] = [];
  
    this.sideBarServices.menu.forEach(menuItem => {
      const modulePermissions = this.permissions[menuItem.title.toLowerCase()]; 
  
      // Verificar si el módulo tiene permisos o si está presente en los permisos, independientemente de si tiene submenús o no
      if ((modulePermissions && modulePermissions.length > 0) || menuItem.title.toLowerCase() in this.permissions) {
        // Si el módulo tiene permisos o está presente en los permisos, agregarlo al menú filtrado
        if (menuItem.submenu && menuItem.submenu.length > 0) {
          // Filtrar los submenús y agregar solo aquellos con permisos
          const filteredSubmenu = menuItem.submenu.filter((subitem: { title: string; }) => {
            const subitemTitleNormalized = this.normalizeTitle(subitem.title);
            return Object.keys(this.permissions).some(module => this.permissions[module].includes(subitemTitleNormalized));
          });
  
          // Si hay al menos una acción permitida en el submenú, agregar el elemento al menú filtrado
          if (filteredSubmenu.length > 0) {
            const newItem = { ...menuItem, submenu: filteredSubmenu };
            filteredMenuItems.push(newItem);
          }
        } else {
          // Si no hay submenús, agregar directamente el módulo al menú filtrado
          filteredMenuItems.push(menuItem);
        }
      }
    });
  
  
    this.menuItems = filteredMenuItems;
    console.log('Menú filtrado:', this.menuItems);
  }
  isAdmin(): boolean {
    return this.authService.getCurrentUser()?.role === 'Administrador';
  }

  normalizeTitle(title: string): string {
    return title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  getRoles(): void {
    this.apiService.getById(ApiService.apiURLBack + 'rol', this.role).subscribe({
      next: (response: any) => {
        const permissions = JSON.parse(response.permissions);
        console.log(permissions);
        this.permissions = permissions;
        this.loaderService.updateIsLoading(false);
        this.filterMenuItemsByPermissions();

      },
      error: (error: { message: any }) => {
        console.error(error.message);
      },
    });
  }
}




