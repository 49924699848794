<p-toast></p-toast>
<title title="Configuración  >  Divisas"></title>
<div class="global-main-table">
  <p-button *ngIf="canEdit" class="global-button-add" iconPos="left" (onClick)="addNewPar()"> Agregar </p-button>
  <p-table
    id="tablePar"
    #dtPar
    [value]="par"
    [tableStyle]="{ 'min-width': '25rem' , 'margin-top':'1.5%' }"
    [paginator]="true"
    [rows]="6"
    [rowsPerPageOptions]="[1, 2, 3, 4 , 5 , 6]"
    selectionMode="single"
    autocomplete="off"
    class="global-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Nombre</th>
        <th *ngIf="canEdit">Acciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-p let-i="rowIndex">
      <tr>
        <td>{{ p.name }}</td>
        <td *ngIf="canEdit">
          <div class="optionsButtons">
            <button pButton (click)="deletePar(p.id)"  icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
        </div>
      </td>
      </tr>
    </ng-template>
  </p-table>
</div>


<div class="card flex justify-content-center gap-2" *ngIf="displayDelete">
  <p-confirmDialog #cd [style]="{width: '50vw'}">
    <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" ></button>
      <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" ></button>
  </ng-template>
  </p-confirmDialog>
</div>



<div *ngIf="displayBasic">
  <p-dialog
    header="Nueva Par"
    [(visible)]="displayBasic"
    [style]="{ width: '28vw'}"
    [breakpoints]="{ '1782px': '30vw', '1559px': '35vw' , '1394px': '40vw' ,
    '1275px': '45vw','1138px': '50vw','1048px': '55vw', '960px': '60vw'  , '890px': '70vw'}"
    class="global-dialog-add"
    [draggable]="false"
  >

    <div class="inputs">
      <div class="labelInput">
        <div class="flex py-2 justify-content-center">
          <label for="name">Origen</label>
          <p-dropdown requerid placeholder="Seleccionar" class="p-dropdown-display" appendTo="body" optionLabel="name" [options]="currenciesOrigen" [showClear]="true" (onChange)="onCurrencySelectedOrigin($event)"></p-dropdown>
      </div>
       
      </div>

        <div class="labelInput">
          <label for="symbol">Destino</label>
          <p-dropdown requerid placeholder="Seleccionar" class="p-dropdown-display" appendTo="body" optionLabel="name" [options]="currenciesDestiny" [showClear]="true" (onChange)="onCurrencySelectedDestiny($event)"></p-dropdown>
        </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button class="global-button-add" icon="pi pi-save" (onClick)="saveNewPar()" label="Guardar"></p-button>
    </ng-template>
  </p-dialog>
</div>
