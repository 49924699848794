import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { Transaction } from '../model/Transaction';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { TransactionPendient } from '../model/TransactionPendient';
import { AuthService } from '../auth/guards/auth-service.service';
import { TransactionPendientRequest } from '../model/TransactionPendientRequest';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';

export const customCurrencyMaskConfig2: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 3,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};


@Component({
  selector: 'app-transaction-pendient',
  templateUrl: './transaction-pendient.component.html',
  styleUrls: ['./transaction-pendient.component.css'],
  providers: [ConfirmationService, MessageService,
    { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig2 }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionPendientComponent implements OnInit {

  transactions!: TransactionPendient[];
  clients!: any;
  typesOfTransaction!: any;
  currencies!: any;
  selectedIdClient:any;
  selectedIdTypeTransaction:any;
  newObject!: any;
  newTransaction!: TransactionPendient;
  userID!: number;
  pointByUserID: any;
  par: any;
  selectedIdCurrencyPar:any;
  selectedTypeOfTransaction:any;
  selectedPair:any;
  transactionRequest!: TransactionPendientRequest;
  parsedNumber!: number;
  parsedNumber2!: number;

  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService, private authService: AuthService) {
      this.userID= parseInt(this.authService.userId);
    }

  ngOnInit(): void {
    this.getClients();
    this.getTypeOfTransaction();
    this.getCurrencies();
    this.getPoints();
    this.getPar();
    this.selectedIdClient = 1;
    this.newTransaction = new TransactionPendient();
    this.transactionRequest = new TransactionPendientRequest();
    this.newTransaction.quantity = undefined;
  }

  getClients(){
    this.apiService.get(ApiService.apiURLBack + 'cliente').subscribe({
      next: (response: any) => {
        this.clients = response;
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPoints(){
    this.apiService.get(ApiService.apiURLBack + 'punto').subscribe({
      next: (response: any) => {
        this.pointByUserID = 1;
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getTypeOfTransaction(){
    this.apiService.get(ApiService.apiURLBack + 'tipoTransaccion').subscribe({
      next: (response: any) => {
        this.typesOfTransaction = response;
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPar(){
    this.apiService.get(ApiService.apiURLBack + 'par').subscribe({
      next: (response: any) => {
        this.par = response;
      this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getCurrencies(){
    this.apiService.get(ApiService.apiURLBack + 'divisa').subscribe({
      next: (response: any) => {
        this.currencies = response;
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }
  onCurrencySelectedPar(event:any) {
    if (!event.value) {
      this.selectedIdCurrencyPar = undefined;
    }
    else {
      this.selectedIdCurrencyPar = event.value.id;
      this.selectedPair = this.par.find((p: { id: any; }) => p.id == event.value.id);

    }
  }

  onClientSelected(event:any){

    if (!event.value) {
      this.selectedIdClient = undefined;
    }
    else {
    this.selectedIdClient = event.value.id;
    }
  }

  onTypeTransactionSelected(event:any){
    if (!event.value) {
      this.selectedIdTypeTransaction= undefined;
    }
    else {
    this.selectedIdTypeTransaction = event.value.id;
    this.selectedTypeOfTransaction = this.typesOfTransaction.find((p: { id: any; }) => p.id == event.value.id);
    }
  }

  clearCampos (){
    this.newTransaction = new Transaction();
    this.newTransaction.quantity = undefined;
    this.selectedTypeOfTransaction = undefined;
    this.selectedPair = undefined;
   }


  generatingTransaction(){
    this.newTransaction.user_id = this.userID;
    this.newTransaction.customer_id = this.selectedIdClient;
    this.newTransaction.type_transaction_id = this.selectedIdTypeTransaction;
    this.newTransaction.pair_id = this.selectedIdCurrencyPar;
    this.newTransaction.point_of_sell_id = this.pointByUserID;          //TODO: agregado manualmente

    if(this.newTransaction.type_transaction_id != undefined &&
      this.newTransaction.quantity!=undefined && this.newTransaction.pair_id!=undefined
      && this.newTransaction.customer_id !=undefined && this.newTransaction.point_of_sell_id !=undefined) {
        this.transactionRequest.customer_id = this.newTransaction.customer_id;
    this.transactionRequest.pair_id = this.newTransaction.pair_id;
    this.transactionRequest.point_of_sell_id = this.newTransaction.point_of_sell_id;
    this.transactionRequest.type_transaction_id = this.newTransaction.type_transaction_id;
    this.transactionRequest.user_id = this.newTransaction.user_id;

    const numberAsString = this.newTransaction.quantity ?? '0';
    this.parsedNumber = parseFloat(numberAsString);
    this.transactionRequest.quantity = this.parsedNumber;

    const numberAsString2 = this.newTransaction.price ?? '0';
    const parsedNumber2 = Number(numberAsString2);
    
    if (isNaN(parsedNumber2) || parsedNumber2 < 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'La cantidad ingresada debe ser un número válido y mayor o igual a 0',
        life: 3000,
      });
      return;
    }
    this.transactionRequest.price = parsedNumber2; 

    this.apiService
    .post(ApiService.apiURLBack + 'transaccionpendiente', this.transactionRequest)
    .subscribe({
      next: (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Transaccion agregada exitosamente',
          life: 2000,
        });
          // Espera 2 segundos antes de redirigir
          setTimeout(() => {
            this.router.navigate(['/pages/transacciones/ver-pendientes']);
          }, 2000);
        this.clearCampos();
        this.getPar();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
   } else {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Debe completar todos los campos',
      life: 3000,
    });
    return;
  }



  }
}
