<p-toast></p-toast>
<title title="Configuracion  >  Usuarios"></title>

<div class="global-main-table" *ngIf="boxes">
  <div class="container-table">
    <div class="filters">
      <div>
        <span class="p-input-icon-left">
          <i class="pi pi-search" style="font-size: 18px; color: #797979"></i>
          <input
            pInputText
            type="text"
            (input)="
              dtboxes.filterGlobal($any($event.target).value, 'contains')
            "
            placeholder="Filtrar por:"
          />
        </span>
      </div>
    </div>
    <p-table
      #dtboxes
      dataKey="id"
      [value]="boxes"
      [sortField]="'date'"
      [sortOrder]="-1"
      [tableStyle]="{ 'min-width': '25rem', 'margin-top': '1.5%' }"
      [paginator]="true"
      [rows]="6"
      [rowsPerPageOptions]="[1, 2, 3, 4, 5, 6]"
      [globalFilterFields]="[
        'currency_id.name',
        'mount',
        'point_of_sell.user.name'
      ]"
      autocomplete="off"
      class="global-table"
    >
      <ng-template pTemplate="header">
        <tr></tr>
        <tr>
          <th class="p-d-flex p-jc-between p-ai-center">Divisas</th>
          <th class="p-d-flex p-jc-between p-ai-center">Cantidad</th>
          <th class="p-d-flex p-jc-between p-ai-center">Caja</th>
          <th class="p-d-flex p-jc-between p-ai-center">Acciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-box let-i="rowIndex">
        <tr>
          <td>{{ box.currency_id.name }}</td>
          <td>{{ formatNumber(box.mount) }}</td>
          <td>{{ box.point_of_sell.user.name }}</td>
          <td>
            <div class="optionsButtons">
              <button
                pButton
                (click)="entryMount(box)"
                icon="pi pi-plus-circle"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                pButton
                (click)="egressMount(box)"
                icon="pi pi-minus-circle"
                class="p-button-rounded p-button-danger p-button-text"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div *ngIf="displayEgress">
    <p-dialog
      header="Quitar Monto"
      [(visible)]="displayEgress"
      [style]="{ width: '28vw' }"
      [breakpoints]="{
        '1782px': '30vw',
        '1559px': '35vw',
        '1394px': '40vw',
        '1275px': '45vw',
        '1138px': '50vw',
        '1048px': '55vw',
        '960px': '60vw',
        '890px': '70vw'
      }"
      class="global-dialog-add"
      [draggable]="false"
    >
      <!-- ... Otros campos de entrada para el nuevo punto ... -->
      <div class="inputs">
        <div class="labelInput">
          <label for="name">Cantidad</label>
          <input
            tabindex="1"
            type="text"
            currencyMask
            name="quantity"
            inputmode="numeric"
            [(ngModel)]="mountEgressRequest"
            quantity
            pInputText
            step="any"
            maxlength="18"
            id="quantity"
            placeholder="Escriba una cantidad"
          />
        </div>
      </div>
      <ng-template pTemplate="footer">
        <p-button
          class="global-button-add"
          icon="pi pi-save"
          (click)="saveEgress()"
          label="Guardar"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>

  <div *ngIf="displayEntry">
    <p-dialog
      header="Ingresar Monto"
      [(visible)]="displayEntry"
      [style]="{ width: '28vw' }"
      [breakpoints]="{
        '1782px': '30vw',
        '1559px': '35vw',
        '1394px': '40vw',
        '1275px': '45vw',
        '1138px': '50vw',
        '1048px': '55vw',
        '960px': '60vw',
        '890px': '70vw'
      }"
      class="global-dialog-add"
      [draggable]="false"
    >
      <!-- ... Otros campos de entrada ... -->
      <div class="inputs">
        <div class="labelInput">
          <label for="name">Cantidad</label>
          <input
            tabindex="1"
            type="text"
            currencyMask
            name="quantity"
            inputmode="numeric"
            [(ngModel)]="mountEntryRequest"
            quantity
            pInputText
            step="any"
            maxlength="18"
            id="quantity"
            placeholder="Escriba una cantidad"
          />
        </div>
      </div>
      <ng-template pTemplate="footer">
        <p-button
          class="global-button-add"
          icon="pi pi-save"
          (click)="saveEntry()"
          label="Guardar"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
