import { Injectable } from '@angular/core';
import { User, UserWithToken } from '../../model/user.interface';
import { LoginCredentials } from '../../model/login-credentials.interface';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, } from 'rxjs';
import { map } from 'rxjs/operators';

const USER_LOCAL_STORAGE_KEY = 'userData';
const USERID_LOCAL_STORAGE_KEY = 'userId';
const ROLE_LOCAL_STORAGE_KEY = 'role';
const ROLEID_LOCAL_STORAGE_KEY = 'roleId';
const PERMISSIONS_LOCAL_STORAGE_KEY = 'permission';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user = new BehaviorSubject<UserWithToken | null>(null);
  user$ = this.user.asObservable();
  isLoggedIn$: Observable<boolean> = this.user$.pipe(map(Boolean));
  userId:any;
  userRole!: any ;
  rolId!:any;

  constructor(private httpClient: HttpClient, private router: Router) {
    this.loadUserFromLocalStorage();
    this.userRole = localStorage.getItem(ROLE_LOCAL_STORAGE_KEY);
    this.rolId = localStorage.getItem(ROLEID_LOCAL_STORAGE_KEY);
    this.userId = localStorage.getItem(USERID_LOCAL_STORAGE_KEY);
  }

  login(url: string, credentials: LoginCredentials): Observable<any> {
    return this.httpClient.post(url + 'login', credentials);
  }


  handleLoginResponse(response: any): void {
    const userToken = response.access_token;
    const userWithToken: UserWithToken = {
      username: response.Usuario.name,
      role: response.Usuario.rol.name,
      token: userToken,
      id: response.Usuario.id,
      permissions: JSON.parse(response.Usuario.rol.permissions),
    };
  
    this.saveRoleToLocalStore(response.Usuario.rol.name);
    this.saveRoleIdToLocalStore(response.Usuario.rol.id);
    this.savePermissionsUserToLocalStore(JSON.parse(response.Usuario.rol.permissions));
    this.saveTokenToLocalStore(userToken);
    this.saveIdUserToLocalStore(response.Usuario.id);
    this.pushNewUser(userWithToken);
    this.redirectUserAfterLogin(userWithToken);
  }

  redirectUserAfterLogin(userWithToken: UserWithToken): void {
    if (userWithToken.role === 'Administrador') {
      setTimeout(() => {
        window.location.href = '/pages/dashboard';
      }, 1000);
    } else {
      const firstPermission = this.getFirstPermission(userWithToken.permissions);
      if (firstPermission) {
        setTimeout(() => {
          window.location.href = `/pages/${firstPermission}`;
        }, 1000);
      }
    }
  }


   getFirstPermission(permissions: any): string | null {
    const modules = Object.keys(permissions);
    for (const module of modules) {
        const modulePermissions = permissions[module];
        if (modulePermissions.length > 0) {
            return `${module}/${modulePermissions[0]}`;
        }
    }
    return null;
}
  saveRoleToLocalStore(role: any) {
    localStorage.setItem(ROLE_LOCAL_STORAGE_KEY, role);
  }

  saveRoleIdToLocalStore(roleid: any) {
    localStorage.setItem(ROLEID_LOCAL_STORAGE_KEY, roleid);
  }

  saveIdUserToLocalStore(userId: string): void {
    localStorage.setItem(USERID_LOCAL_STORAGE_KEY, userId);
  }

  savePermissionsUserToLocalStore(permission: any[]): void {
    const permissionsJSON = JSON.stringify(permission);
    localStorage.setItem(PERMISSIONS_LOCAL_STORAGE_KEY, permissionsJSON);
  }

  logout(): void {
    this.removeUserFromLocalStorage();
    this.user.next(null);
    this.router.navigateByUrl('/login');
  }

  private redirectToDashboard(): void {
    this.router.navigateByUrl('/pages');
  }

  private pushNewUser(userWithToken: UserWithToken) {
    this.user.next(userWithToken);
  }

  private loadUserFromLocalStorage(): void {
    const userToken = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
    const rol = localStorage.getItem(ROLE_LOCAL_STORAGE_KEY);
    const permissionsJSON = localStorage.getItem(PERMISSIONS_LOCAL_STORAGE_KEY);
    if (userToken) {
      const userWithToken: UserWithToken = {
        username: '',
        token: userToken,
        role: rol || '',
        id: 0,
        permissions: permissionsJSON ? JSON.parse(permissionsJSON) : []
      };
      this.user.next(userWithToken);
    }
  }

  getCurrentUser(): User | null {
    return this.user.getValue();
  }
  
  private saveTokenToLocalStore(userToken: string): void {
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, userToken);
  }

  private removeUserFromLocalStorage(): void {
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    localStorage.removeItem(USERID_LOCAL_STORAGE_KEY);
    localStorage.removeItem(ROLE_LOCAL_STORAGE_KEY);
    localStorage.removeItem(USERID_LOCAL_STORAGE_KEY);
  }
}
