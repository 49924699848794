export const FIELDS_TABLE_POINT = [
    {
      title: "Nombre de caja",
      field: "name",
      type: "text"
    },
    {
      title: "Nombre del usuario",
      field: "name",
      type: "text"
    },
    {
      title: "Acciones",
      field: "actions",
      type: "text"
    }
  ]
