<p-toast></p-toast>

<section class="content" style="margin-left: 1rem">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="card card-info">
          <div class="card-header" style="background-color: #707dbf">
            <h3 class="card-title" style="background-color: #707dbf">Inicio</h3>
          </div>

          <form class="form-horizontal">
            <div class="card-body">
              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-2 col-form-label"
                  >Divisas</label
                >
                <div class="col-sm-6">
                  <p-dropdown
                    requerid
                    placeholder="Seleccionar"
                    class="p-dropdown-globalBox"
                    appendTo="body"
                    optionLabel="name"
                    [options]="currencies"
                    [showClear]="true"
                    (onChange)="onCurrencySelected($event)"
                  ></p-dropdown>
                </div>
              </div>
              <div class="form-group row">
                <label for="mount" class="col-sm-2 col-form-label"
                  >Cantidad</label
                >
                <div class="col-sm-6">
                  <input
                    tabindex="2"
                    type="text"
                    style="font-weight: 600"
                    inputmode="numeric"
                    name="mount"
                    mount
                    [(ngModel)]="newBox.mount"
                    currencyMask
                    #mount="ngModel"
                    pInputText
                    step="any"
                    maxlength="18"
                    id="mount"
                    placeholder="Escriba una cantidad"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-2 col-form-label"
                  >Asignar Caja</label
                >
                <div class="col-sm-6">
                  <p-dropdown
                    requerid
                    placeholder="Seleccionar"
                    class="p-dropdown-globalBox"
                    appendTo="body"
                    optionLabel="name"
                    [options]="points"
                    [showClear]="true"
                    (onChange)="onPointSelected($event)"
                  ></p-dropdown>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-default float-right"
                style="background-color: #707dbf; color: white"
                (click)="addNewBox(dtBoxes)"
              >
                Agregar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="table">
  <p-table
    id="tableBoxes"
    #dtBoxes
    [value]="boxesByTable"
    [paginator]="true"
    [rows]="4"
    [rowsPerPageOptions]="[1, 2, 3, 4, 5, 6]"
    selectionMode="single"
    autocomplete="off"
    class="global-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let column of fieldsTableBoxInit">{{ column.title }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-box let-i="rowIndex">
      <tr>
        <td>{{ box.name }}</td>
        <td>{{ box.mount.toLocaleString("es") }}</td>
        <td>
          <div class="optionsButtons">
            <button
              pButton
              (click)="deleteBox(box)"
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-text"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="generatingBox">
    <button
      type="submit"
      class="btn btn-default float-right"
      style="background-color: #707dbf; color: white"
      (click)="generatingBox()"
    >
      Generar caja
    </button>
  </div>
</div>
