import { pointOfChange } from './../../model/pointsOfChange';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable} from 'rxjs';
import { User } from 'src/app/model/User';
import { ApiService } from '../api-service/apiService/api.service';
import { Currency } from 'src/app/model/Currency';
import { Role } from 'src/app/model/Role';
import { Client } from 'src/app/model/Client';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  // BehaviorSubjects para almacenar los datos de diferentes entidades
  private usersData$ = new BehaviorSubject<User[]>([]);
  private divisasData$ = new BehaviorSubject<Currency[]>([]);
  private pointOfChangeData$ = new BehaviorSubject<pointOfChange[]>([]);
  private rolesData$ = new BehaviorSubject<Role[]>([]);
  private clientsData$ = new BehaviorSubject<Client[]>([]);

  constructor(private http: HttpClient) { 
    this.fetchUsersData();
    this.fetchPointOfChangeData();
    this.fetchRolesData();
    this.fetchClientsData();
    this.fetchDivisasData();
  }

  // Método para obtener los datos de usuarios y actualizar el BehaviorSubject correspondiente
  fetchUsersData(): void {
    this.http.get<User[]>(ApiService.apiURLBack + 'usuario').subscribe(
      (users: User[]) => this.usersData$.next(users),
      (error: any) => console.error('Error al obtener datos de usuarios:', error)
    );
  }
  
  fetchPointOfChangeData(): void {
    this.http.get<any>(ApiService.apiURLBack + 'punto').subscribe(
      (point: any) => this.pointOfChangeData$.next(point),
      (error: any) => console.error('Error al obtener los puntos de cambio:', error)
    );
  }

  fetchRolesData(): void {
    this.http.get<any>(ApiService.apiURLBack + 'rol').subscribe(
      (roles: any) => this.rolesData$.next(roles),
      (error: any) => console.error('Error al obtener datos de los roles:', error)
    );
  }

  fetchClientsData(): void {
    this.http.get<any>(ApiService.apiURLBack + 'cliente').subscribe(
      (clients: any) => this.clientsData$.next(clients),
      (error: any) => console.error('Error al obtener datos de clientes:', error)
    );
  }

  fetchDivisasData(): void {
    this.http.get<any>(ApiService.apiURLBack + 'divisa').subscribe(
      (divisas: any) => this.divisasData$.next(divisas),
      (error: any) => console.error('Error al obtener datos de divisas:', error)
    );
  }

  // Método para obtener los datos almacenados en el BehaviorSubject de usuarios
  getUsersData(): Observable<any> {
    return this.usersData$.asObservable();
  }

  getPointOfChangeData(): Observable<any> {
    return this.pointOfChangeData$.asObservable();
  }

  getRolesData(): Observable<any> {
    return this.rolesData$.asObservable();
  }

  getClientsData(): Observable<any> {
    return this.clientsData$.asObservable();
  }

  // Método para obtener los datos almacenados en el BehaviorSubject de divisas
  // Puedes agregar métodos similares para otras entidades si es necesario
  getDivisasData(): Observable<any> {
    return this.divisasData$.asObservable();
  }
}