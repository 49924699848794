
export class TransactionByTableRequest{

  constructor(
  public customer?: string,
  public type_transaction?: string,
  public par?: string,
  public quantity?: string,
  public mountFinal?:string,
  public price?:string,
  ){}
}
