
export class TransactionPendientRequest{

  constructor(
  public user_id?:number,
  public customer_id?: number,
  public type_transaction_id?: number,
  public price?: number,
  public pair_id?: number,
  public point_of_sell_id?: number,
  public quantity?:number
  ){}
}
