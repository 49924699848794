import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { pointOfChange } from '../model/pointsOfChange';
import { FIELDS_TABLE_POINT } from '../model/mocks/mock-fieldsTablePointOfChange';
import { User } from '../model/User';
import { AuthService } from '../auth/guards/auth-service.service';

@Component({
  selector: 'app-point-of-change',
  templateUrl: './point-of-change.component.html',
  styleUrls: ['./point-of-change.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointOfChangeComponent implements OnInit {
  points!: pointOfChange[];
  fieldsTablePoints = FIELDS_TABLE_POINT;
  displayBasic!: boolean;
  displayDelete!: boolean;
  displayEdit!:boolean;
  newPoint!:any;
  users!: User[];
  selectedUserId!:any;
  selectPointId!:any;
  selectedUser!:any;
  user!:any;
  canEdit: boolean = false;
  role: any;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private authService: AuthService
  ) {

    this.user = this.authService.getCurrentUser();
    this.role = this.authService.userRole;
    if (this.user && this.user.permissions && this.user.permissions['configuracion']) {
      this.canEdit = this.user.permissions['configuracion'].includes('punto de cambio_editar') || this.role == 'Administrador';
    }
  }

  ngOnInit(): void {
    this.getPoints();
    this.getPointsHability();
  }

  getPoints(){
    this.apiService.get(ApiService.apiURLBack + 'punto').subscribe({
      next: (response: any) => {
        this.points = response;

     this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPointsHability(){
    this.apiService.get(ApiService.apiURLBack + 'puntoUsuarios').subscribe({
      next: (response: any) => {
       this.users= response.map((point: any) => ({
        id: point.id,
        name: point.name,
      }));

      this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  deletePoint(id:number){
    this.displayDelete = true;
    this.cdr.detectChanges();
    this.confirmationService.confirm({
      message: 'Deseas eliminar este punto?',
      header: 'Confirmar borrado',
      icon: 'pi pi-info-circle',
      accept: () => {
        // Filtramos el arreglo para obtener el punto que coincida con el ID proporcionado
        const pointToDelete = this.points.find((user) => user.id === id);

        if (pointToDelete) {
          const index = this.points.indexOf(pointToDelete);
          if (index !== -1) {
            this.points.splice(index, 1);
              // Forzar la actualización de la tabla
            this.cdr.detectChanges();
            this.apiService
              .delete(ApiService.apiURLBack + 'punto', id)
              .subscribe(
                () => {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Confirmado',
                    detail: 'Registro eliminado',
                  });
                  this.getPoints();
                },
                (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error al eliminar el registro',
                  });
                }
              );
          }
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Punto no encontrado',
            detail: 'No se encontró el punto a eliminar',
          });
        }
      },
    });
  }

  editPoint(point:any) {
    this.displayEdit = true;
    this.newPoint = {
      name: point.name,
      user_id: point.user?.id,
    };
    this.selectedUser = this.users.find((user) => user.id === point.user?.id);
    this.selectPointId = point.id;
    }

    saveEditPoint() {

        this.newPoint.user_id = this.selectedUserId;
        this.apiService.put(ApiService.apiURLBack + 'punto', this.selectPointId, this.newPoint)
        .subscribe(
          () => {
            this.messageService.add({
              severity: 'info',
              summary: 'Confirmado',
              detail: 'Registro editado',
            });
            this.getPoints();
            this.cdr.detectChanges();
            this.displayEdit =false;
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al editar el registro',
            });
          }
        );

    }

  onUserSelected(event: any) {
    this.selectedUserId = event.value.id;
  }

  addNewPoint() {
    this.newPoint = {
      name: '',
      user_id: null,
    };
    this.displayBasic = true;
  }

  saveNewPoint(){
    if (this.selectedUserId == null) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error al agregar el punto de cambio',
      detail: 'Por favor, seleccione un usuario antes de guardar el punto.',
      life: 3000,
    });
    this.selectedUser = this.users[0];
    this.selectedUserId = this.users[0].id;

    this.cdr.detectChanges();
  }
  else {
    this.newPoint.user_id = this.selectedUserId;
    this.apiService
    .post(ApiService.apiURLBack + 'punto', this.newPoint)
    .subscribe({
      next: (response: any) => {
        this.points.push(response);
        this.displayBasic = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Punto de cambio agregado exitosamente',
          life: 2000,
        });
        this.getPoints();
        this.cdr.detectChanges();
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error al agregar el punto de cambio',
          life: 2000,
        });
      },
    });
  }
  }
}
