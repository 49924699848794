export const FIELDS_TABLE_BOXSTATE = [
    {
      title: "Divisas",
      field: "name",
      type: "text",
    },
    {
      title: "Cantidad",
      field: "mount",
      type: "number"
    },
    {
      title: "Caja",
      field: "point_of_sell.user.name",
      type: "text",
      filter: true // Agregar la propiedad filter para activar el filtro en esta columna
    },
    {
      title: "",
      field: "name",
      type: "text"
    }
  ];