
<title title="Configuración > Roles"></title>
<div class="global-main-table">
  <p-table
    id="tableRoles"
    #dtRoles
    [value]="roles"
    [tableStyle]="{ 'min-width': '25rem' , 'margin-top':'1.5%' }"
    [paginator]="true"
    [rows]="3"
    [rowsPerPageOptions]="[1, 2, 3, 4 , 5 , 6]"
    selectionMode="single"
    autocomplete="off"
    class="global-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let column of fieldsTableRoles">{{column.title}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rol let-i="rowIndex">
      <tr (click)="redirectTo(rol.id)">
        <td>{{ rol.acronym }}</td>
        <td>{{ rol.name }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

