import { Transaction } from './../model/Transaction';
import { AuthService } from './../auth/guards/auth-service.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { TransactionPendient } from '../model/TransactionPendient';
import { FIELDS_TABLE_TRANSACTION_PENDIENT } from '../model/mocks/mock-fieldsTableTransactionPendient';
import { NumberFormatService } from '../services/numberFormat/number-format-service.service';

@Component({
  selector: 'app-show-transactions-pendient',
  templateUrl: './show-transactions-pendient.component.html',
  styleUrls: ['./show-transactions-pendient.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowTransactionsPendientComponent implements OnInit {

  transactionsPendients!: TransactionPendient[];
  fieldsTableTransactionsPendients = FIELDS_TABLE_TRANSACTION_PENDIENT;
  newPoint!: any;
  userID: number;
  pointByUserID: any;
  displayDelete: any;
  canEdit: any;
  user: any;
  role: any;

  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,private authService:AuthService,private numberFormatService: NumberFormatService) { 
      this.userID = parseInt(this.authService.userId);
      this.user = this.authService.getCurrentUser();
      this.role = this.authService.userRole;
      if (this.user && this.user.permissions && this.user.permissions['transacciones']) {
        this.canEdit = this.user.permissions['transacciones'].includes('ver pendientes_editar') || this.role == 'Administrador';
      }
    }

  ngOnInit(): void {
    this.getTransactionPendient();
    this.getPoints();
  }

  getTransactionPendient(){
    this.apiService.get(ApiService.apiURLBack + 'transaccionespendiente').subscribe({
      next: (response: any) => {
        this.transactionsPendients = response;


        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPoints() {
    this.apiService.get(ApiService.apiURLBack + 'punto').subscribe({
      next: (response: any) => {
        this.pointByUserID = response.find(
          (item: { user: { id: any } }) => item.user.id === this.userID
        ).id;
        if (!this.pointByUserID) {
          this.pointByUserID = 1;
        }
        this.addPoint();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  
  addPoint() {
    this.newPoint = {
      point_of_sell_id: 1,
    };
  }

  finalyTransaction(transactionPendient:any) {
    this.newPoint.point_of_sell_id = this.pointByUserID;
    this.apiService
    .postTransactionPendient(ApiService.apiURLBack + 'transaccionpendiente', transactionPendient.id, this.newPoint)
    .subscribe({
      next: (response: any) => {

        this.messageService.add({
          severity: 'success',
          summary: 'Transacción finalizada exitosamente',
          life: 2000,
        });
        this.getTransactionPendient();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          sticky: true,  // Hace que el mensaje sea persistente
        });
        },
    });
   }
   deletetransaction (transactionPendient:any){
    this.displayDelete = true;
    this.cdr.detectChanges();
    this.confirmationService.confirm({
      message: 'Deseas eliminar esta transacción?',
      header: 'Confirmar operación',
      icon: 'pi pi-info-circle',
      accept: () => {
        const transactionToDelete = this.transactionsPendients.find(transaction => transaction.id === transactionPendient.id);
        if (transactionToDelete) {
          const index = this.transactionsPendients.indexOf(transactionToDelete);
          if (index !== -1) {
            this.transactionsPendients.splice(index, 1);

            this.cdr.detectChanges();
            this.apiService
              .delete(ApiService.apiURLBack + 'transaccion', transactionPendient.id)
              .subscribe(
                () => {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Confirmado',
                    detail: 'Registro eliminado',
                  });
                  this.getTransactionPendient();
                },
                (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error al eliminar el registro',
                  });
                }
              );
          }
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Transacción no encontrada',
            detail: 'No se encontró la transacción a eliminar',
          });
        }
      },
    });
  }

  formatNumber(value: number): string {
    return this.numberFormatService.formatNumber(value);
  }
}
