<p-toast></p-toast>
<div class="global-main-big-table" *ngIf="transactions">

      
    <ng-container>
      <p-table
        [value]="table"
        styleClass="global-table-dashboard-transactions"
        [scrollable]="true"
        [scrollHeight]="'ml-auto'"
        [tableStyle]="{ 'min-width' : '20rem', 'min-height': '20rem' }"
        #dtmontos
      >
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th>Compra</th>
          <th>Venta</th>
        </tr>
      </ng-template>
        <ng-template pTemplate="body" let-table let-rowIndex="rowIndex">
          <tr>
            <td style=" background-color: #707dbf; color: white;">{{ table.name }}</td>
            <td>{{ formatNumber(table.buy) }}</td>
            <td>{{ formatNumber(table.sell) }}</td>
          </tr>
        
        </ng-template>
      </p-table>
    </ng-container>

  <div class="container-table">
    <div class="filters">
      <div>
          <span class="p-input-icon-left">
              <i class="pi pi-search" style="font-size: 18px; color: #797979;"></i>
              <input pInputText type="text" (input)="dtTransactions.filterGlobal($any($event.target).value, 'contains')" placeholder="Filtrar por:" />
          </span>
      </div>
      <div>
          <span class="p-input-icon-left">
              <i class="pi pi-calendar" style="font-size: 18px; color: #797979; z-index:2;"></i>
              <p-calendar
                  style="margin-left: -1rem; margin-right: 1rem;"
                  styleClass="campo2"
                  [(ngModel)]="    rangeDates"
                  selectionMode="range"
                  [readonlyInput]="true"
                  placeholder="Fecha/s"
                  [maxDate]="dateTime"
                  [icon]="calendarIcon"
                  [dateFormat]="'dd-mm-yy'"
                  (onSelect)="applyDateFilter()">
                  >
              </p-calendar>
          </span>
      </div>
      <div *ngIf="isFilter">
      <span class="icon">
        <p-button icon="pi pi-filter-slash" styleClass="p-button-filter" (click)="clearFilter()" class="p-button-text"></p-button>
      </span>
    </div>
  </div>

<p-table
id="tableTransactions"
#dtTransactions
[value]="isFilter ? filteredData : transactions"
[tableStyle]="{ 'min-width': '25rem' }"
[paginator]="true"
[scrollable]="true"
scrollHeight="400px" 
[rows]="50" 
[rowsPerPageOptions]="[50, 100]"
[sortField]="'date'"
[sortOrder]="-1"
frozenHeader="true"
[globalFilterFields]="['price','mount','currency_origin.name','pair.name', 'point_of_sell.name', 'date' , 'type_transaction.name', 'customer.name']"
selectionMode="single"
autocomplete="off"
class="global-table"
>
    <ng-template pTemplate="header">
      <tr>
        <th>Fecha</th>
        <th>Transacción</th>
        <th>Divisas</th>
        <th>Cantidad</th>
        <th>Precio</th>
        <th>Monto</th>
        <th>Cliente</th>
        <th>Punto de cambio</th>
        <th *ngIf="canEdit">Acciones</th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transaction let-i="rowIndex">
      <tr>
        <td>{{ formatDateOfViuwToString(transaction.date)}}</td>
        <td>{{ transaction.type_transaction.name }}</td>
        <td>{{ transaction.pair.name }}</td>
        <td>{{ formatNumber(transaction.quantity) }}</td>
        <td>{{ formatNumber(transaction.price) }}</td>
        <td>{{ formatNumber(transaction.mount) }}</td>
        <td>{{ transaction.customer.name }}</td>
        <td>{{ transaction.point_of_sell.name }}</td>
        <td *ngIf="canEdit">
          <div class="optionsButtons">
           <button pButton (click)="deletetransaction(transaction)"  icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
        </div>
      </td>
      </tr>
    </ng-template>
    
  </p-table>

</div>
</div>


<div class="card flex justify-content-center gap-2" *ngIf="displayDelete">
  <p-confirmDialog #cd [style]="{width: '50vw'}">
    <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" ></button>
      <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" ></button>
  </ng-template>
  </p-confirmDialog>
</div>