export const FIELDS_TABLE_ROLE = [
    {
      title: "Siglas",
      field: "acronym",
      type: "text"
    },
    {
      title: "Nombre",
      field: "name",
      type: "text"
    }
  ]
