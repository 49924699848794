import { TypeTransaction } from "./TypeTransaction";

export class TransactionRequest{

  constructor(
  public user_id?:number,
  public customer_id?: number,
  public type_transaction_id?: number,
  public pair_id?: number,
  public point_of_sell_id?: number,
  public quantity?: number
  ){}
}
