import { FIELDS_TABLE_BOXINIT } from './../model/mocks/mock-fieldsTableBoxInit';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { Currency } from '../model/Currency';
import { box } from '../model/Box';
import { BoxInit } from '../model/BotInit';
import { pointOfChange } from '../model/pointsOfChange';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { boxRequest } from '../model/BoxRequest';
import { AuthService } from '../auth/guards/auth-service.service';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';

export const customCurrencyMaskConfig2: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 3,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@Component({
  selector: 'app-box-init',
  templateUrl: './box-init.component.html',
  styleUrls: ['./box-init.component.css'],
  providers: [ConfirmationService, MessageService,
    { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig2 }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxInitComponent implements OnInit {
  currencies!: Currency[];
  fieldsTableBoxInit= FIELDS_TABLE_BOXINIT;
  newCurrency!: any;
  boxes !: boxRequest[];
  newBox!: box;
  boxRequest!: boxRequest;
  boxesByTable!: any[];
  boxInit!: BoxInit;
  selectedIdCurrency!:any;
  selectedIdPoint!:any;
  newObject: any;
  points!: pointOfChange[];
  users!: any;
  form!: FormGroup;
  hasTriedToAdd = false;
  isValid = false;
  parsedNumber!: number;
  user!:any;
  canEdit: boolean = false;
  role: any;

  constructor( private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService, private formBuilder: FormBuilder,private authService: AuthService) {
      this.form = this.formBuilder.group({
        mount: ['', [
          Validators.required,
          Validators.pattern(/^\d{1,14}(?:\.\d{1,5})?$/)
        ]]
      });

    }

  ngOnInit(): void {
    this.newBox = new box();
    this.boxRequest = new boxRequest();
    this.boxes = new Array<boxRequest>;
    this.boxesByTable = [];
    this.getCurrencies();
    this.getPoints();
  }

  getCurrencies(){
    this.apiService.get(ApiService.apiURLBack + 'divisa').subscribe({
      next: (response: any) => {
        this.currencies = response.map((currency: any) => ({
          id: currency.id,
          name: currency.name,
        }));;

        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPoints(){
    this.apiService.get(ApiService.apiURLBack + 'punto').subscribe({
      next: (response: any) => {
        this.points = response.map((point: any) => ({
          id: point?.id || null,
          name: point?.name || null,
        }));

    this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  onPointSelected(event: any) {
    if (!event.value) {
      this.selectedIdPoint = undefined;
    } else {
      this.selectedIdPoint = event.value.id;
    }
  }
  onCurrencySelected(event:any) {
    if (!event.value) {
      this.selectedIdCurrency = undefined;
      this.newObject = {
      name : '',
      mount : undefined
      }
    } else {
      this.selectedIdCurrency = event.value.id;
      this.newObject = {
      name : event.value.name,
      mount : 0
      }
    }
  }

  addNewBox(dtBoxes: any) {
    this.hasTriedToAdd = true;
    if (this.selectedIdCurrency !== undefined && this.newBox.mount != null) {
      const existingBox = this.boxesByTable.find(box => box.name === this.newObject.name);
      if (existingBox) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Ya existe en la caja esa divisa.',
          life: 3000,
        });
        return;
      }

      const numberAsString = this.newBox.mount ?? '0';
      this.parsedNumber = parseFloat(numberAsString);
      this.boxRequest.mount = parseFloat(this.parsedNumber.toFixed(5));
      this.newObject.mount = this.boxRequest.mount;
      this.boxesByTable.push(this.newObject);

      this.boxRequest.currency_id = this.selectedIdCurrency;
      const newBoxCopy: boxRequest = { ...this.boxRequest };

      this.boxes.push(newBoxCopy);
      this.boxes = [...this.boxes];
      this.cdr.detectChanges();


      this.newBox = new box();
      this.newBox.mount = '0';
      // Actualizar la tabla después de agregar una nueva caja
      dtBoxes.reset(); // Reiniciar la tabla
      dtBoxes.totalRecords = this.boxesByTable.length; // Actualizar el número total de registros
      dtBoxes._first = 0; // Volver a la primera página
      this.hasTriedToAdd = false; // Reiniciar la bandera después de agregar con éxito
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Debe seleccionar una divisa y completar el campo de cantidad.',
        life: 3000,
      });
      this.isValid = false;
      return;
    }

  }

  deleteBox (box: any){
    box.mount = parseFloat(box.mount.toFixed(5));
    const currencyToDelete = {
      currency_id : this.currencies.find((currency) => currency.name === box.name)?.id,
      mount : box.mount
    }
    const index = this.boxes.findIndex((box) => box.currency_id === currencyToDelete.currency_id && box.mount === currencyToDelete.mount);
    if (index !== -1) {
      this.boxes.splice(index, 1);
      this.boxesByTable.splice(index,1);
      this.cdr.detectChanges();
      console.log('Box deleted:', currencyToDelete);
    }
  }

  generatingBox() {
    const boxInit: BoxInit = {
      box: this.boxes,
    };

    this.apiService.postBox(ApiService.apiURLBack+ 'caja',this.selectedIdPoint, boxInit).subscribe({
      next: (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Caja agregada exitosamente',
          life: 2000,
        });
        setTimeout(() => {
          window.location.href = '/pages/dashboard';
        }, 1000);
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
        console.log(error);
      },
    });
  }
}
