import { pointOfChange } from './model/pointsOfChange';

import { LogginComponent } from './loggin/loggin.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { RolesComponent } from './roles/roles.component';
import { UsersComponent } from './users/users.component';
import { CurrenciesComponent } from './currencies/currencies.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages/pages.component';
import { PointOfChangeComponent } from './point-of-change/point-of-change.component';
import { ClientsComponent } from './clients/clients.component';
import { HistoryTransactionComponent } from './history-transaction/history-transaction.component';
import { TransactionComponent } from './transaction/transaction.component';
import { BoxInitComponent } from './box-init/box-init.component';
import { AmountReportComponent } from './amount-report/amount-report.component';
import { TransactionPendientComponent } from './transaction-pendient/transaction-pendient.component';
import { BoxStatusComponent } from './box-status/box-status.component';
import { ShowCotizacionComponent } from './show-cotizacion/show-cotizacion.component';
import { ShowTransactionsPendientComponent } from './show-transactions-pendient/show-transactions-pendient.component';
import { AuthGuard } from './auth/guards/AuthGuard';
import { ParComponent } from './par/par.component';
import { BoxMovimientosComponent } from './box-movimientos/box-movimientos.component';
import { PermissionComponent } from './permission/permission.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LogginComponent,
  },
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['dashboard'],
          titulo: 'Dashboard'
        }
      },
      {
        path: 'caja/inicio',
        component: BoxInitComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['inicio'],
          titulo: 'Inicio de Caja'
        }
      },
      {
        path: 'caja/movimientos',
        component: BoxMovimientosComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['movimientos'],
          titulo: 'Movimientos de Caja'
        }
      },
      {
        path: 'caja/estado',
        component: BoxStatusComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['estado'],
          titulo: 'Estado de Caja'
        }
      },
      {
        path: 'configuracion',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        data: {
          titulo: 'Configuración'
        }
      },
      {
        path: 'configuracion/usuarios',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['usuarios'],
          titulo: 'Configuración de Usuarios'
        }
      },
      {
        path: 'configuracion/clientes',
        component: ClientsComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['clientes'],
          titulo: 'Configuración de Clientes'
        }
      },
      {
        path: 'configuracion/puntos-de-cambio',
        component: PointOfChangeComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['punto de cambio'],
          titulo: 'Configuración de Puntos de Cambio'
        }
      },
      {
        path: 'configuracion/roles',
        component: RolesComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['roles'],
          titulo: 'Configuración de Roles'
        }
      },
      {
        path: 'configuracion/permisos',
        component: PermissionComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['permisos'],
          titulo: 'Configuración de Permisos'
        }
      },
      {
        path: 'configuracion/divisas',
        component: CurrenciesComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['divisas'],
          titulo: 'Configuración de Divisas'
        }
      },
      {
        path: 'configuracion/par',
        component: ParComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['par'],
          titulo: 'Configuración de Par de divisas'
        }
      },
      {
        path: 'cotizaciones/mostrar',
        component: ShowCotizacionComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['mostrar'],
          titulo: 'Cotizaciones'
        }
      },
      {
        path: 'transacciones/ver-pendientes',
        component: ShowTransactionsPendientComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['ver pendientes'],
          titulo: 'Ver Transacciones Pendientes'
        }
      },
      {
        path: 'transacciones/historial',
        component: HistoryTransactionComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['historial'],
          titulo: 'Historial de transacciones'
        }
      },
      {
        path: 'transacciones/nueva-transaccion',
        component: TransactionComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['nueva'],
          titulo: 'Nueva Transacción'
        }
      },
      {
        path: 'transacciones/pendientes',
        component: TransactionPendientComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['pendientes'],
          titulo: 'Pendientes de Transacción'
        }
      }

    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports:[RouterModule]
})
export class AppRoutingModule { }
