
export class TransactionPendient{

  constructor(
  public id?:number,
  public user_id?:number,
  public customer_id?: number,
  public type_transaction_id?: number,
  public price?: string,
  public pair_id?: number,
  public point_of_sell_id?: number,
  public quantity?:string
  ){}
}
