<p-toast></p-toast>
<nav class="main-header navbar navbar-expand navbar-white navbar-light">

  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
  </ul>

  <ul class="navbar-nav ml-auto">

    <!-- Icono de usuario con nombre y opción "Salir" dentro del menú desplegable -->
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <i class="fas fa-user"></i>
        {{ role }}
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right user-menu">
        <!-- Utiliza *ngIf para mostrar los elementos solo si el role es 'Administrador' -->
        <ng-container *ngIf="role === 'Administrador'">
          <div class="dropdown-item user-info" (click)="eliminarDatos()">
            <i class="fas fa-trash"></i> Restaurar
          </div>
        </ng-container>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item user-logout" (click)="logout()">
          <i class="fas fa-sign-out-alt"></i> Salir
        </a>
      </div>

    </li>

  </ul>

</nav>


