export const FIELDS_TABLE_BOXINIT = [
    {
      title: "Divisas",
      field: "name",
      type: "text"
    },
    {
        title: "Cantidad",
        field: "mount",
        type: "number"
      },
    {
      title: "Acciones",
      field: "actions",
      type: "text"
    }
  ]
