<p-toast></p-toast>
<title title="Configuración  >  Usuarios"></title>
<div class="global-main-table" *ngIf="users">
  <p-button *ngIf="canEdit" class="global-button-add" iconPos="left" (onClick)="addNewUser()"> Agregar </p-button>
  <p-table
    id="tableUser"
    #dtUsers
    [value]="users"
    [tableStyle]="{ 'min-width': '25rem' , 'margin-top':'1.5%' }"
    [paginator]="true"
    [rows]="6"
    [rowsPerPageOptions]="[1, 2, 3, 4 , 5 , 6]"
    selectionMode="single"
    autocomplete="off"
    class="global-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Nombre</th>
        <th>Email</th>
        <th>Rol</th>
        <th *ngIf="canEdit">Acciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user let-i="rowIndex">
      <tr (click)="redirectTo(user.id)">
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.rol.name }} </td>
        <td *ngIf="canEdit">
          <div class="optionsButtons" >
            <button pButton (click)="editUser(user)" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-plain" ></button>
            <button pButton (click)="deleteUser(user.id)"  icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
        </div>
      </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="card flex justify-content-center gap-2" *ngIf="displayDelete">
  <p-confirmDialog #cd [style]="{width: '50vw'}">
    <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" ></button>
      <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" ></button>
  </ng-template>
  </p-confirmDialog>
</div>

<div *ngIf="displayBasic">
  <p-dialog
    header="Nuevo usuario"
    [(visible)]="displayBasic"
    class="global-dialog-add"
    [breakpoints]="{ '1782px': '40vw', '1559px': '45vw' , '1394px': '50vw' ,
     '1275px': '55vw','1138px': '60vw','1048px': '65vw', '960px': '70vw'  , '890px': '77vw'}"
    [style]="{ width: '35vw' }"
    [draggable]="false"
  >
    <div class="inputs">
      <div class="labelInput">
        <label for="name">Nombre</label>
        <input tabindex="1" type="text" id="name" name="name" required name pInputText [(ngModel)]="newUser.name" />
      </div>
      <div class="labelInput">
        <label for="password">Password</label>
        <input tabindex="2" type="text" id="password" name="password" required password  #password="ngModel" pInputText [(ngModel)]="newUser.password" />
        <div [hidden]="password.valid || !password.touched" class="errors">
          <div *ngIf="password.errors?.['required']">
              Contraseña es requerida
          </div>
      </div>
      </div>
      <div class="labelInput">
        <label for="email">Email</label>
        <input tabindex="3" type="text" id="email" name="email" required #email="ngModel" email pInputText [(ngModel)]="newUser.email" />

        <div [hidden]="email.valid || !email.touched" class="errors">
          <div>
              <span *ngIf="email.errors?.['required']">
                  Email es requerido
              </span>
          </div>
          <div>
              <span *ngIf="email.errors?.['email']">
                  Email incorrecto
              </span>
          </div>
      </div>

      </div>
      <div class="labelInput">
        <label for="role">Roles</label>
        <p-dropdown class="p-dropdown-display" requerid placeholder="Seleccionar" appendTo="body" optionLabel="name" [options]="roles" [showClear]="true" [(ngModel)]="selectedRole" (onChange)="onRoleSelected($event)"></p-dropdown>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button class="global-button-add" icon="pi pi-save" (click)="saveNewUser()" label="Guardar"></p-button>
    </ng-template>
  </p-dialog>
</div>

<div *ngIf="displayEdit">
  <p-dialog
    header="Editar usuario"
    [(visible)]="displayEdit"
    class="global-dialog-add"
    [breakpoints]="{ '1782px': '40vw', '1559px': '45vw' , '1394px': '50vw' ,
     '1275px': '55vw','1138px': '60vw','1048px': '65vw', '960px': '70vw'  , '890px': '77vw'}"
    [style]="{ width: '35vw' }"
    showEffect="fade"
    [draggable]="false"
  >
    <!-- ... Otros campos de entrada para el nuevo usuario ... -->
    <div class="inputs">
      <div class="labelInput">
        <label for="name">Nombre</label>
        <input tabindex="1" type="text" id="name" name="name" required name pInputText [(ngModel)]="editNewUser.name" />
      </div>
      <div class="labelInput">
        <label for="password">Password</label>
        <input tabindex="2" type="text" id="password" name="password" required password  #password="ngModel" pInputText [(ngModel)]="editNewUser.password" />
        <div [hidden]="password.valid || !password.touched" class="errors">
          <div *ngIf="password.errors?.['required']">
              Contraseña es requerida
          </div>
      </div>
      </div>
      <div class="labelInput">
        <label for="email">Email</label>
        <input tabindex="3" type="text" id="email" name="email" required #email="ngModel" email pInputText [(ngModel)]="editNewUser.email" />

        <div [hidden]="email.valid || !email.touched" class="errors">
          <div>
              <span *ngIf="email.errors?.['required']">
                  Email es requerido
              </span>
          </div>
          <div>
              <span *ngIf="email.errors?.['email']">
                  Email incorrecto
              </span>
          </div>
      </div>

      </div>
      <div class="labelInput">
        <label for="role">Roles</label>
        <p-dropdown class="p-dropdown-display" requerid placeholder="Seleccionar" appendTo="body" optionLabel="name" [options]="roles" [showClear]="true" [(ngModel)]="selectedRole" (onChange)="onRoleSelectedInEdit($event)"></p-dropdown>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button class="global-button-add" icon="pi pi-save" (click)="saveEditUser()" label="Guardar"></p-button>
    </ng-template>
  </p-dialog>
</div>
