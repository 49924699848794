import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { Table } from 'primeng/table';
import { FIELDS_TABLE_BOXMOVIMIENTOS } from '../model/mocks/mock-fieldsTableBoxMovimientos';
import { NumberFormatService } from '../services/numberFormat/number-format-service.service';
@Component({
  selector: 'app-box-movimientos',
  templateUrl: './box-movimientos.component.html',
  styleUrls: ['./box-movimientos.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxMovimientosComponent implements OnInit {

  boxes!: any;
  displayEgress!:boolean;
  displayEntry!:boolean;
  mountEntry!:number;
  mountEgress!:number;
  newObjectEntry!:any;
  newObjectEgress!:any;
  selectedIDbox!:number;
  fieldsTableStateBox = FIELDS_TABLE_BOXMOVIMIENTOS;
  point_of_sell: any;
  @ViewChild('dtboxes') dtboxes: any; // Agrega esta línea para obtener la referencia al componente p-table
  items!: SelectItem[];
  item!: string;
  selectedCountries1: string[] = [];
  selectedCountries2: string[] = [];
  calendarIcon = 'pi pi-calendar';
  filteredData: any[] = [];
  isFilter = false;
  rangeDates: Date[] | undefined;
  value: string | undefined;
  records: any;
  dateTime = new Date();


  constructor(    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,private numberFormatService: NumberFormatService
  ) {
    this.items = [];
    this.dateTime.setDate(this.dateTime.getDate());
  }

  ngOnInit(): void {
    this.mountEgress = 0;
    this.mountEntry = 0;
    this.getBoxes();
  }

  applyDateFilter() {
    console.log(this.rangeDates);
    if (this.rangeDates && this.rangeDates.length === 2) {
      this.isFilter = true;
      const startDate = new Date(this.rangeDates[0]).getTime();
      const endDate = new Date(this.rangeDates[1]).getTime();  
      console.log(startDate);
      console.log(endDate);
  
        // Si las fechas son iguales, ajustar el filtrado para incluir todo el día
    if (startDate === endDate && this.rangeDates) {
      const newDate = this.formatDateToString(this.rangeDates[0]);
      console.log("Son iguales");
      console.log(newDate);
      this.filteredData = this.boxes.filter((item: any) => {
        const itemDate = item.date.split('T')[0]; 
        console.log(itemDate);
        return itemDate === newDate;
      });
    }

    else {
      this.filteredData = this.boxes.filter((item: any) => {
        const itemDate = new Date(item.date.split('T')[0]).getTime();
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    } else {
      this.filteredData = this.boxes;
    }
  }

  formatDateOfBackToString(dateString: any): string {
    return dateString.slice(8, 10) + dateString.slice(4, 7) + '-' + dateString.slice(0, 4);
  }
  
  formatDateToString(dateString: Date): string {
  const date = new Date(dateString);
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
  }

  formatDateOfViuwToString(dateString: any): string {
    const date = new Date(dateString);
    const formattedDate = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    return formattedDate;
  }


  clearFilter() {
    this.isFilter = false;
    this.rangeDates = undefined;
    this.filteredData = this.boxes;
  }
  getBoxes(){
    this.apiService.get(ApiService.apiURLBack + 'cajas').subscribe({
      next: (response: any) => {
        this.boxes = response;

        const uniqueNames = new Set<string>();
        this.point_of_sell = this.boxes
          .map((box: any) => ({
            id: box.point_of_sell.user.id,
            name: box.point_of_sell.user.name,
          }))
          .filter((item: any) => {
            if (!uniqueNames.has(item.name)) {
              uniqueNames.add(item.name);
              return true;
            }
            return false;
          });
        this.items = this.point_of_sell;
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  egressMount(box: any) {

    this.displayEgress =true;
    this.newObjectEgress = {
      currency_id : box.currency_id.id,
      mount : 0
    }
    this.selectedIDbox = box.point_of_sell.id;
  }

  entryMount(box: any) {
    this.displayEntry =true;
    this.newObjectEntry = {
      currency_id : box.currency_id.id,
      mount : 0
    }
    this.selectedIDbox = box.point_of_sell.id;
  }

  saveEgress() {
    this.newObjectEgress.mount = this.mountEgress;
    console.log(this.newObjectEgress);
    this.apiService.post(ApiService.apiURLBack+ 'caja/' + this.selectedIDbox + '/egreso' ,this.newObjectEgress).subscribe({
      next: (response: any) => {
        this.displayEgress = false;
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Cambio confirmado',
        });
        this.getBoxes();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });

  }

  saveEntry(){
    this.newObjectEntry.mount = this.mountEntry;
   this.apiService.post(ApiService.apiURLBack+ 'caja/' + this.selectedIDbox + '/ingreso' ,this.newObjectEntry).subscribe({
      next: (response: any) => {
        this.displayEntry = false;
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Cambio confirmado',
        });
        this.getBoxes();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  filter(event: any ) {
    console.log(event);
  }

  clear(table: Table) {
    table.clear();
}

formatNumber(value: number): string {
  return this.numberFormatService.formatNumber(value);
}

}


