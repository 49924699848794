<p-toast></p-toast>
<title title="Configuracion  >  Usuarios"></title>
<div class="global-main-table" *ngIf="points">
  <p-button *ngIf="canEdit"  class="global-button-add" iconPos="left" (onClick)="addNewPoint()"> Agregar </p-button>
  <p-table
    id="tablePoints"
    #dtPoints
    [value]="points"
    [tableStyle]="{ 'min-width': '35rem' , 'margin-top':'1.5%' }"
    [paginator]="true"
    [rows]="6"
    [rowsPerPageOptions]="[1, 2, 3, 4 , 5 , 6]"
    selectionMode="single"
    autocomplete="off"
    class="global-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Nombre de caja</th>
        <th>Nombre de usuario</th>
        <th *ngIf="canEdit">Acciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-point let-i="rowIndex">
      <tr>
        <td>{{ point.name }}</td>
        <td>{{ point.user.name }}</td>
        <td *ngIf="canEdit" >
          <div class="optionsButtons">
            <button pButton (click)="editPoint(point)" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-plain" ></button>
            <button pButton (click)="deletePoint(point.id)"  icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
        </div>
      </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="card flex justify-content-center gap-2" *ngIf="displayDelete">
    <p-confirmDialog #cd [style]="{width: '50vw'}">
      <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" ></button>
        <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" ></button>
    </ng-template>
    </p-confirmDialog>
  </div>

<div *ngIf="displayBasic">
    <p-dialog
      header="Nuevo Punto"
      [(visible)]="displayBasic"
      [style]="{ width: '28vw'}"
      [breakpoints]="{ '1782px': '30vw', '1559px': '35vw' , '1394px': '40vw' ,
      '1275px': '45vw','1138px': '50vw','1048px': '55vw', '960px': '60vw'  , '890px': '70vw'}"
      class="global-dialog-add"
      [draggable]="false"
    >
      <!-- ... Otros campos de entrada para el nuevo punto ... -->
      <div class="inputs">
        <div class="labelInput">
          <label for="name">Nombre</label>
          <input tabindex="1" type="text" id="name" name="name" required name pInputText [(ngModel)]="newPoint.name" />
        </div>

        <div class="labelInput">
          <label for="user">Usuario</label>
          <p-dropdown class="p-dropdown-display" requerid placeholder="Seleccionar" appendTo="body" optionLabel="name"  [(ngModel)]="selectedUser"
          [options]="users" [showClear]="true" (onChange)="onUserSelected($event)"></p-dropdown>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <p-button class="global-button-add" icon="pi pi-save" (click)="saveNewPoint()" label="Guardar"></p-button>
      </ng-template>
    </p-dialog>
  </div>

  <div *ngIf="displayEdit">
    <p-dialog
      header="Editar Punto"
      [(visible)]="displayEdit"
      [style]="{ width: '28vw'}"
      [breakpoints]="{ '1782px': '30vw', '1559px': '35vw' , '1394px': '40vw' ,
      '1275px': '45vw','1138px': '50vw','1048px': '55vw', '960px': '60vw'  , '890px': '70vw'}"
      class="global-dialog-add"
      [draggable]="false"
    >
      <!-- ... Otros campos de entrada para el nuevo punto ... -->
      <div class="inputs">
        <div class="labelInput">
          <label for="name">Nombre</label>
          <input tabindex="1" type="text" id="name" name="name" required name pInputText [(ngModel)]="newPoint.name" />
        </div>

        <div class="labelInput">
          <label for="user">Usuario</label>
          <p-dropdown class="p-dropdown-display" requerid placeholder="Seleccionar" appendTo="body" optionLabel="name" [options]="users" [showClear]="true" [ngModel]="selectedUser" (onChange)="onUserSelected($event)"></p-dropdown>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <p-button class="global-button-add" icon="pi pi-save" (click)="saveEditPoint()" label="Guardar"></p-button>
      </ng-template>
    </p-dialog>
  </div>
