import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu : any []= [
    {
      icon: 'nav-icon fas fa-tachometer-alt',
      title: 'Dashboard',
      routerLink: '/pages/dashboard',
      submenu: []
    },
    {
      icon: 'nav-icon fas fa-money-bill',
      title: 'Caja',
      submenu: [
        { title: 'Inicio', routerLink: '/pages/caja/inicio'},
        { title: 'Movimientos', routerLink: '/pages/caja/movimientos'},
        { title: 'Estado', routerLink: '/pages/caja/estado'}
      ]
    },
    {
      icon: 'nav-icon fas fa-chart-line',
      title: 'Cotizaciones',
      submenu: [
        { title: 'Mostrar', routerLink: '/pages/cotizaciones/mostrar'}
      ]
    },
    {
      icon: 'nav-icon fas fa-exchange-alt',
      title: 'Transacciones',
      submenu: [
        { title: 'Historial', routerLink: '/pages/transacciones/historial'},
        { title: 'Nueva', routerLink: '/pages/transacciones/nueva-transaccion'},
        { title: 'Pendientes', routerLink: '/pages/transacciones/pendientes'},
        { title: 'Ver pendientes', routerLink: '/pages/transacciones/ver-pendientes'}
      ]
    },
   
    {
      icon: 'nav-icon fas fa-cog',
      title: 'Configuracion',
      submenu: [  
        { title: 'Clientes', routerLink: '/pages/configuracion/clientes'},
        { title: 'Usuarios', routerLink: '/pages/configuracion/usuarios'},
        { title: 'Roles', routerLink: '/pages/configuracion/roles'},
        { title: 'Permisos', routerLink: '/pages/configuracion/permisos'},
        { title: 'Divisas', routerLink: '/pages/configuracion/divisas'},
        { title: 'Punto de Cambio', routerLink: '/pages/configuracion/puntos-de-cambio'},
        { title: 'Par', routerLink: '/pages/configuracion/par'}
            ]
    },
  ];

  menuCajero : any []= [
    {
      icon: 'nav-icon fas fa-chart-line',
      title: 'Cotizaciones',
      submenu: [
        { title: 'Mostrar', routerLink: '/pages/cotizaciones/mostrar'},
      ]
    },
    {
      icon: 'nav-icon fas fa-exchange-alt',
      title: 'Transacciones',
      submenu: [
        { title: 'Historial', routerLink: '/pages/transacciones/historial'},
        { title: 'Nueva', routerLink: '/pages/transacciones/nueva-transaccion'},
        { title: 'Ver pendientes', routerLink: '/pages/transacciones/ver-pendientes'}
      ]
    }
  ]

  
  menuSupervisor: any []= [
    {
      icon: 'nav-icon fas fa-chart-line',
      title: 'Cotizaciones',
      submenu: [
        { title: 'Mostrar', routerLink: '/pages/cotizaciones/mostrar'},
      ]
    },
    {
      icon: 'nav-icon fas fa-money-bill',
      title: 'Caja',
      submenu: [
        { title: 'Inicio', routerLink: '/pages/caja/inicio'},
        { title: 'Movimientos', routerLink: '/pages/caja/movimientos'},
        { title: 'Estado', routerLink: '/pages/caja/estado'}
      ]
    },
    {
      icon: 'nav-icon fas fa-exchange-alt',
      title: 'Transacciones',
      submenu: [
        { title: 'Historial', routerLink: '/pages/transacciones/historial'},
        { title: 'Nueva', routerLink: '/pages/transacciones/nueva-transaccion'},
        { title: 'Pendientes', routerLink: '/pages/transacciones/pendientes'},
        { title: 'Ver pendientes', routerLink: '/pages/transacciones/ver-pendientes'}
      ]
    }
  ]
}
