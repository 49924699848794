export const FIELDS_TABLE_CURRENCY = [

  {
      title: "Id",
      field: "id",
      type: "number"
    },
    {
      title: "Nombre",
      field: "name",
      type: "text"
    },
    {
      title: "Simb",
      field: "symbol",
      type: "text"
    },
    {
      title: "Acciones",
      field: "actions",
      type: "text"
    }
  ]
