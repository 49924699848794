
<p-toast></p-toast>    
  <ng-container>

<div class="header"  *ngIf="canEdit">
  <button type="submit" class="btn btn-primary" style="background-color: #707dbf; border-color: #707dbf; float: right;" (click)="generatingCotizacion()">Nueva</button>
</div>
<section class="content" style="height: 100%;">
    <div class="container-fluid">
      <div class="row">
        <ng-container *ngFor="let cotizacion of cotizaciones">
          <div class="col-md-3">
            <div class="card card-widget widget-user">
              <div class="widget-user-header bg-info" style="height: 50px;">
                <h3 class="widget-user-username"></h3>
                <div>
                <h5 class="widget-user-desc">{{ cotizacion.pair_id.name }}
                  <button *ngIf="canEdit" class="btn btn-primary" style="background-color: #17a2b8!important; border-color: #17a2b8!important; float: right; margin-top: -0.5rem; margin-left: -1rem;" (click)="editCotizacion(cotizacion)">
                    <i class="fas fa-pencil-alt"></i> <!-- Icono de lápiz (ajusta el icono según tu biblioteca de íconos) -->
                  </button>
                </h5>
              </div>
              </div>
              <div class="card-footer" style="height: 100px;">
                <div class="row">
                  <div class="col-sm-6 border-right" style="height: 100px; margin-top: -3rem;">
                    <div class="description-block custom-description-block0">
                      <h5 class="description-header" >{{ cotizacion.mount_buy    }}</h5>
                      <span class="description-text">COMPRA</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="description-block custom-description-block">
                      <h5 class="description-header">{{ cotizacion.mount_sell  }}</h5>
                      <span class="description-text">VENTA</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>


  <div class="card flex justify-content-center gap-2" *ngIf="displayDelete">
    <p-confirmDialog #cd [style]="{width: '50vw'}">
      <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" ></button>
        <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" ></button>
    </ng-template>
    </p-confirmDialog>
  </div>


  <div *ngIf="displayBasic">
    <p-dialog
      header="Nueva cotizacion"
      [(visible)]="displayBasic"
      [style]="{ width: '28vw'}"
      [breakpoints]="{ '1782px': '30vw', '1559px': '35vw' , '1394px': '40vw' ,
      '1275px': '45vw','1138px': '50vw','1048px': '55vw', '960px': '60vw'  , '890px': '70vw'}"
      class="global-dialog-add"
      [draggable]="false"
    >

    <div class="inputs">
      <div class="labelInput">
        <label for="name">Par de divisas</label>
        <p-dropdown requerid class="p-dropdown-display" appendTo="body" optionLabel="name" [options]="currencies" [(ngModel)]="selectedCurrency" [showClear]="true" (onChange)="onCurrencySelected($event)"></p-dropdown>
      </div>

      <div class="labelInput" style="margin-top: 1.5rem;">
        <label for="quantity">Precio de compra</label>
        <input tabindex="1" name="quantity" [(ngModel)]="cotizacion.mount_buy" autocomplete="off" #quantity="ngModel" inputmode="numeric" type="text" id="quantity" currencyMask pInputText placeholder="Escriba una cantidad">
        <div>
        </div>
      </div>
    
      <div class="labelInput">
        <label for="sell">Precio de venta</label>
        <input tabindex="2" name="sell" [(ngModel)]="cotizacion.mount_sell" autocomplete="off" #sell="ngModel" inputmode="numeric" type="text" id="sell" currencyMask  pInputText placeholder="Escriba una cantidad">
        <div>
        </div>
      </div>
    </div>

      <ng-template pTemplate="footer">
        <p-button class="global-button-add" icon="pi pi-save" (onClick)="saveNewCotization()" label="Guardar"></p-button>
      </ng-template>
    </p-dialog>
  </div>


  <div *ngIf="displayEdit">
    <p-dialog
      header="Editar cotizacion"
      [(visible)]="displayEdit"
      [style]="{ width: '28vw'}"
      [breakpoints]="{ '1782px': '30vw', '1559px': '35vw' , '1394px': '40vw' ,
      '1275px': '45vw','1138px': '50vw','1048px': '55vw', '960px': '60vw'  , '890px': '70vw'}"
      class="global-dialog-add"
      [draggable]="false"
    >

      <div class="inputs">
        <div class="labelInput">
          <label for="name">Par de divisas</label>
          <p-dropdown requerid class="p-dropdown-display" appendTo="body" optionLabel="name" [options]="currencies" [(ngModel)]="selectedCurrency" [showClear]="true" (onChange)="onCurrencySelected($event)"></p-dropdown>
        </div>

        <div class="labelInput" style="margin-top: 1.5rem;">
          <label for="quantity">Precio de compra</label>
          <input tabindex="1" name="quantity" [(ngModel)]="cotizacion.mount_buy" autocomplete="off" #quantity="ngModel" inputmode="numeric" type="text" id="quantity" currencyMask pInputText placeholder="Escriba una cantidad">
          <div>
          </div>
        </div>
      
        <div class="labelInput">
          <label for="sell">Precio de venta</label>
          <input tabindex="2" name="sell" [(ngModel)]="cotizacion.mount_sell" autocomplete="off" #sell="ngModel" inputmode="numeric" type="text" id="sell" currencyMask pInputText placeholder="Escriba una cantidad">
          <div>
          </div>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <p-button class="global-button-delete" icon="pi pi-trash" (onClick)="deleteCotization()" label="Eliminar"></p-button>
        <p-button class="global-button-add" icon="pi pi-save" (onClick)="saveEditCotization(cotizacion)" label="Guardar"></p-button>
      </ng-template>
    </p-dialog>
  </div>

</ng-container>

       