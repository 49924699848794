export const FIELDS_TABLE_BOXMOVIMIENTOS = [
    {
      title: "Divisas",
      field: "name",
      type: "text",
    },
    {
      title: "Cantidad",
      field: "mount",
      type: "number"
    },
    {
      title: "Estado",
      field: "status",
      type: "text"
      },
    {
      title: "Caja",
      field: "point_of_sell.user.name",
      type: "text",
      filter: true // Agregar la propiedad filter para activar el filtro en esta columna
    }
  ];