
export interface BackendData {
    caja: string[];
    dashboard: string[];
    cotizaciones: string[];
    configuracion: string[];
    transacciones: string[];
  }
  
  export interface SubmenuItem {
    title: string;
    activo: boolean;
  }