import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../../services/api-service/apiService/api.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/guards/auth-service.service';
import { User } from 'src/app/model/user.interface';
import { DataStoreService } from 'src/app/services/dataStore/dataStoreService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  currentUser$ = this.authService.user$;
  displayDelete!: boolean;
  role!:any;


  constructor(private authService: AuthService, private apiService: ApiService,
    private cdr: ChangeDetectorRef,private dataStoreService: DataStoreService,
    private messageService: MessageService, private router: Router) {
      this.role = this.authService.getCurrentUser()?.role;
    }
  ngOnInit(): void {

  }

  loginAsUser(user: User): void {

  }
  logout(): void {
    this.authService.logout();
  }
  eliminarDatos() {
    this.logout();
    this.apiService.get(ApiService.apiURLBack + 'restore').subscribe({
      next: (response: any) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Datos eliminados',
        });
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error al eliminar datos',
          life: 2000,
        });
      },
    });
  }

}
