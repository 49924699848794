export const FIELDS_TABLE_USER = [

      {
        title: "Nombre",
        field: "name",
        type: "text"
      },
      {
        title: "Email",
        field: "email",
        type: "text"
      },
      {
        title: "Rol",
        field: "rol",
        type: "text"
      },
      {
        title: "Acciones",
        field: "actions",
        type: "text"
      }]
