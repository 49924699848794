import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FIELDS_TABLE_PAR } from '../model/mocks/mock-fieldsTablePar';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { Par } from '../model/Par';
import { AuthService } from '../auth/guards/auth-service.service';

@Component({
  selector: 'app-par',
  templateUrl: './par.component.html',
  styleUrls: ['./par.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParComponent implements OnInit {

  fieldsTablePar  = FIELDS_TABLE_PAR;
  selectedPar !: any;
  displayBasic!: boolean;
  displayDelete!:boolean;
  displayEdit!:boolean;
  selectedId!:any;
  par!: Par[];
  currenciesOrigen: any;
  currenciesDestiny: any;
  currency_origin_id!: any;
  currency_destiny_id!: any;
  newPar: any;
  user!:any;
  canEdit: boolean = false;
  role: any;

  constructor(  private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private authService: AuthService
  ) {

    this.user = this.authService.getCurrentUser();
    this.role = this.authService.userRole;
    if (this.user && this.user.permissions && this.user.permissions['configuracion']) {
      this.canEdit = this.user.permissions['configuracion'].includes('par_editar') || this.role == 'Administrador';
    }
  }
  ngOnInit(): void {
    this.getCurrencies();
    this.getPar();
  }

  getCurrencies(){
    this.apiService.get(ApiService.apiURLBack + 'divisa').subscribe({
      next: (response: any) => {
        this.currenciesOrigen = response;
        this.currenciesDestiny = response;
            // Forzar la actualización de la tabla
    this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPar(){
    this.apiService.get(ApiService.apiURLBack + 'par').subscribe({
      next: (response: any) => {
        this.par = response;
            // Forzar la actualización de la tabla
      this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  deletePar(id : any) {

      this.displayDelete = true;
      this.cdr.detectChanges();
      this.confirmationService.confirm({
        message: 'Deseas eliminar este Par?',
        header: 'Confirmar borrado',
        icon: 'pi pi-info-circle',
        accept: () => {
          const parToDelete = this.par.find((user) => user.id === id);
          if (parToDelete) {
            const index = this.par.indexOf(parToDelete);
            if (index !== -1) {
              this.par.splice(index, 1);
                // Forzar la actualización de la tabla
              this.cdr.detectChanges();
              this.apiService
                .delete(ApiService.apiURLBack + 'par', id)
                .subscribe(
                  () => {
                    this.messageService.add({
                      severity: 'info',
                      summary: 'Confirmado',
                      detail: 'Registro eliminado',
                    });
                    this.getCurrencies();
                  },
                  (error) => {
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: 'Error al eliminar el registro',
                    });
                  }
                );
            }
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Par no encontrado',
              detail: 'No se encontró el par a eliminar',
            });
          }
        },
      });

  }

  onCurrencySelectedOrigin(event:any) {
    if (!event.value) {
      this.currency_origin_id = undefined;
    }
    else {
      this.currency_origin_id = event.value.id;
    }
  }

  onCurrencySelectedDestiny(event:any) {
    if (!event.value) {
      this.currency_destiny_id = undefined;
    }
    else {
      this.currency_destiny_id = event.value.id;
    }
  }

  addNewPar(){
    this.displayBasic = true;
    this.newPar = {
      currency_origin_id: 0,
      currency_destiny_id: 0,
  };
  }

  saveNewPar() {
    if (this.currency_origin_id != null && this.currency_destiny_id !=null) {
      this.newPar.currency_origin_id = this.currency_origin_id;
      this.newPar.currency_destiny_id = this.currency_destiny_id;
      this.apiService
      .post(ApiService.apiURLBack + 'par', this.newPar)
      .subscribe({
        next: (response: any) => {
          this.par.push(response);
          this.displayBasic = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Par agregado exitosamente',
            life: 2000,
          });
          this.getPar();
          this.newPar.currency_origin_id = null;
          this.newPar.currency_destiny_id = null;
          this.cdr.detectChanges();
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error al agregar el par',
            life: 2000,
          });
        },
      });

    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error al agregar el par',
        detail: 'Por favor, seleccione divisas correctamente antes de guardar el par.',
        life: 3000,
      });
      this.cdr.detectChanges();
    }



    }
  }


