import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NumberFormatService {
  formatNumber(value: number): string {
    const numberString = value.toLocaleString('es');
    const parts = numberString.split('.');
  
    if (parts[0].length > 3) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return parts.join('.');
  }
}