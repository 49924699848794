import { Validators } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FIELDS_TABLE_USER } from '../model/mocks/mock-fieldsTableUser';
import { User } from '../model/User';
import { USERS } from '../model/mocks/mock-users';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { Router } from '@angular/router';
import { ApiService } from '../services/api-service/apiService/api.service';
import { Role } from '../model/Role';
import { ResponseUser } from '../model/ResponseUser';
import { DataStoreService } from '../services/dataStore/dataStoreService';
import { AuthService } from '../auth/guards/auth-service.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent implements OnInit {
  fieldsTableUsers = FIELDS_TABLE_USER;
  users!: User[];
  newUser!: any;
  editNewUser!: any;
  roles!: Role[];
  selectedRole: any;
  selectedUser!: any;
  displayBasic!: boolean;
  displayDelete!: boolean;
  displayEdit!: boolean;
  iduserEdit!:any;

  user!:any;
  canEdit: boolean = false;
  role: any;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private dataStoreService: DataStoreService,
    private authService: AuthService
  ) {

    this.user = this.authService.getCurrentUser();
    this.role = this.authService.userRole;
    if (this.user && this.user.permissions && this.user.permissions['configuracion']) {
      this.canEdit = this.user.permissions['configuracion'].includes('usuarios_editar') || this.role == 'Administrador';
    }
  }

  ngOnInit(): void {
    this.getUsers();

    this.apiService.get(ApiService.apiURLBack + 'usuario').subscribe({
      next: (response: any) => {
        this.users = response;
        
            // Forzar la actualización de la tabla
    this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
    this.apiService.get(ApiService.apiURLBack + 'rol').subscribe({
      next: (response: any) => {
        this.roles = response.map((rol: any) => ({
          id: rol.id,
          name: rol.name,
        }));
            // Forzar la actualización de la tabla
    this.cdr.detectChanges();

      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }


  redirectTo(i: number) {}

  onRoleSelected(event: any) {
   if (!event.value) {
    this.newUser.rol_id = undefined;
   }

    this.newUser.rol_id = event.value.id;
  }

  onRoleSelectedInEdit(event:any){
    if (!event.value) {
      this.editNewUser.rol_id = undefined;
     }

      this.editNewUser.rol_id = event.value.id;
    }



  addNewUser() {
    this.displayBasic = true;
    this.newUser = {
      id: 1,
      name: '',
      email: '',
      password: '',
      rol_id: this.roles[0].id,
    };

  }

  getUsers(){
    this.apiService.get(ApiService.apiURLBack + 'usuario').subscribe({
      next: (usersResponse: any) => {
        this.users = usersResponse; // Actualizar la lista completa de usuarios
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  validationUser(){
    if(this.newUser.name !=undefined && this.newUser.email !=undefined && this.newUser.password !=undefined && this.newUser.rol_id !=undefined) {
       return true;
    }
    else {
    this.messageService.add({
      severity: 'error',
      summary: 'Error al agregar el usuario',
      detail: 'Porfavor complete todos los campos',
      life: 2000,
    });
  }
  }

  saveNewUser() {
    if(this.newUser.name !=undefined || this.newUser.email !=undefined || this.newUser.password !=undefined || this.newUser.rol_id !=undefined) {

    this.apiService
    .post(ApiService.apiURLBack + 'usuario', this.newUser)
    .subscribe({
      next: (response: any) => {
        this.users.push(response);

        this.displayBasic = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Usuario agregado exitosamente',
          life: 2000,
        });
        this.getUsers();
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
   }
   else {
    this.messageService.add({
      severity: 'error',
      summary: 'Error al agregar el usuario',
      detail: 'Porfavor complete todos los campos',
      life: 2000,
    });
  }
}

  saveEditUser() {

  if(this.editNewUser.name !=undefined || this.editNewUser.email !=undefined || this.editNewUser.password !=undefined || this.editNewUser.rol_id !=undefined) {

  this.apiService
  .put(ApiService.apiURLBack + 'usuario',this.iduserEdit,this.editNewUser)
  .subscribe({
    next: (response: any) => {
      this.users.push(response);

      this.displayBasic = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Usuario editado exitosamente',
        life: 2000,
      });
      this.getUsers();
      this.cdr.detectChanges();
    },
    error: (error: any) => {
      this.messageService.add({
        severity: 'error',
        summary: 'El usuario ya existe',
        life: 2000,
      });
    }
    });  }
    this.displayEdit =false;
  }


  editUser(user: any) {
    this.editNewUser = {
      name:  user.name,
      email: user.email,
      password: user.password,
      rol_id: user.rol_id,
    };
    this.iduserEdit = user.id;
    this.selectedRole = this.roles.find(f => f.id == user.rol.id);
    this.displayEdit = true;
  }

  deleteUser(id: number) {
    this.displayDelete = true;
    this.cdr.detectChanges();
    this.confirmationService.confirm({
      message: 'Deseas eliminar este usuario?',
      header: 'Confirmar operación',
      icon: 'pi pi-info-circle',
      accept: () => {
        const userToDelete = this.users.find((user) => user.id === id);

        if (userToDelete) {
          const index = this.users.indexOf(userToDelete);
          if (index !== -1) {
            this.users.splice(index, 1);

            this.cdr.detectChanges();
            this.apiService
              .delete(ApiService.apiURLBack + 'usuario', id)
              .subscribe(
                () => {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Confirmado',
                    detail: 'Registro eliminado',
                  });
                  this.getUsers();
                },
                (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error al eliminar el registro',
                  });
                }
              );
          }
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Usuario no encontrado',
            detail: 'No se encontró el usuario a eliminar',
          });
        }
      },
    });
  }
}
