import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Subscription} from 'rxjs';
import {map, filter} from 'rxjs/operators';

@Component({
  selector: 'app-breadcrums',
  templateUrl: './breadcrums.component.html',
  styleUrls: ['./breadcrums.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumsComponent implements OnDestroy{

  public titulo!: String;
  public tituloSubs!: Subscription;

  constructor(private router: Router, private cdr: ChangeDetectorRef){
    
    this.tituloSubs = this.getArguments().subscribe(({titulo})=>{
      this.titulo = titulo;
      document.title = `Exchange - ${titulo}`;
      this.cdr.detectChanges(); // Forzar la detección de cambios
    })
  }

  ngOnDestroy(){
    this.tituloSubs.unsubscribe();
  }

  getArguments(){
    return this.router.events.pipe(
      filter((event: any): event is ActivationEnd => event instanceof ActivationEnd),
      filter((event: ActivationEnd) => event.snapshot.firstChild === null),
      filter((event: ActivationEnd) => !!event.snapshot.data && !!event.snapshot.data['titulo']),
      map((event: ActivationEnd) => event.snapshot.data)
    );
  }

}
