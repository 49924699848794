<p-toast></p-toast>
<div class="global-main-big-table" *ngIf="transactionsPendients">
  <p-table
    id="tableTransactionsPendients"
    #dtTransactionsPendients
    [value]="transactionsPendients"
    [tableStyle]="{ 'min-width': '55rem' , 'margin-top':'1.5%' }"
    [paginator]="true"
    [rows]="6"
    [sortField]="'date'"
    [sortOrder]="-1"
    [rowsPerPageOptions]="[1, 2, 3, 4 , 5 , 6]"
    selectionMode="single"
    autocomplete="off"
    class="global-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Fecha</th>
        <th>Transacción</th>
        <th>Divisas</th>
        <th>Cantidad</th>
        <th>Precio</th>
        <th>Monto</th>
        <th>Cliente</th>
        <th *ngIf="canEdit">Acciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transaction let-i="rowIndex">
      <tr>
        <td>{{ transaction.date | date: 'dd/MM/yyyy'}}</td>
        <td>{{ transaction.type_transaction.name }}</td>
        <td>{{ transaction.pair.name }}</td>
        <td>{{ formatNumber(transaction.quantity) }}</td>
        <td>{{ formatNumber(transaction.price) }}</td>
        <td>{{ formatNumber(transaction.mount) }}</td>
        <td>{{ transaction.customer.name }}</td>
        <td *ngIf="canEdit">
          <div class="optionsButtons">
           <button pButton (click)="finalyTransaction(transaction)" icon="pi pi-check" class="p-button-rounded p-button-text" ></button>
           <button pButton (click)="deletetransaction(transaction)"  icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
        </div>
      </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="card flex justify-content-center gap-2" *ngIf="displayDelete">
  <p-confirmDialog #cd [style]="{width: '50vw'}">
    <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()" ></button>
      <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()" ></button>
  </ng-template>
  </p-confirmDialog>
</div>